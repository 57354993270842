<div class="container-fluid">


  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>
        </span>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="">
          <!-- <h4 class="card-title mb-4"></h4> -->
          <div>
            <ng-stepper #cdkStepper [linear]="true" class="wizard" (selectionChange)="onStepChange($event)">
              @if(hasGas){
              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">{{getUtilityIndex('Gas')}}</span>
                  <span>Gas</span>
                </ng-template>
                <!-- @if(cdkStepper.selectedIndex === 0){ -->

                <div class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="gasGridData" [pageSize]="gasPageSize" [skip]="skip" [sortable]="true"
                      [selectable]="selectableSettings" [pageable]="{
                                  buttonCount: buttonCount,
                                  info: info,
                                  type: type,
                                  pageSizes: pageSizes,
                                  previousNext: previousNext
                                }" [reorderable]="true" filterable="menu" [filter]="gasState.filter" [skip]="skip"
                      [sort]="gasState.sort" [columnMenu]="{ filter: true }"
                      (filterChange)="onGasRateFilterChange($event)" (sortChange)="onGasSortChange($event)"
                      [resizable]="true" scrollable="scrollable" (pageChange)="gasUnitRatePageChange($event)"
                      (selectionChange)="gasSelectionChanged($event)" (dataStateChange)="gasDataStateChange($event)"
                      style="width: 100%;">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="gasSearchedValue" (valueChange)="onMeterConfigFilter($event)"></kendo-textbox> -->
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="gasSearchedValue"
                            [clearButton]="true" (valueChange)="onValueChange($event)" (keydown.enter)="onSearchGasUnits()"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onMeterConfigFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <button class="btn btn-outline-primary btn-sm " (click)="onMeterPdfExport('Excel','gas')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>
                      </ng-template>

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                          <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                          </kendo-grid-string-filter-menu>
                          <small style="color:brown">Search is case-sensitive.</small>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="gasValue" title="Gas Value" filter="numeric"
                        [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="unitLPGValue" title="1 KG/ LPG" filter="numeric"
                        [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="fromDate" title="From Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.fromDate | date: 'dd MMM yyyy'}}
                        </ng-template>

                      </kendo-grid-column>

                      <kendo-grid-column field="toDate" title="To Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span *ngIf="dataItem.toDate; else tillNow">
                            {{ dataItem.toDate | date: 'dd MMM yyyy' }}
                          </span>
                          <ng-template #tillNow>Till now</ng-template>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price" [width]="150"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <!-- Info Icon (Clickable) -->
                          <div class="d-flex">
                            <span *ngIf="dataItem?.fileUrl" kendoTooltip title="Download Document" class="ms-2"
                              (click)="downloadFile(dataItem.fileUrl,'Gas Document')"
                              style="cursor: pointer; font-size: 18px;">
                              <i class="fa fa-download text-success" aria-hidden="true"></i>
                            </span>

                          </div>
                        </ng-template>
                      </kendo-grid-command-column>


                    </kendo-grid>
                  </div>

                  <!--master download buttons start-->

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid [kendoGridBinding]="mySelectionGas.length>0 ? mySelectionGas:gasGridData.data" #pdfGrid>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="gasValue" title="Gas Value" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="unitLPGValue" title="1 KG/ LPG" filter="numeric"
                        [width]="100"></kendo-grid-column>
                      <!-- <kendo-grid-column field="from_and_toDate" title="From and To Date" [width]="200" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.from_and_toDate}}
                        </ng-template>
                      </kendo-grid-column> -->
                      <!-- <kendo-grid-column field="from" title="From Date" [width]="150" filter="date">

                      </kendo-grid-column>

                      <kendo-grid-column field="to" title="To Date" [width]="150" filter="date"> -->
                      <kendo-grid-column field="from_and_toDate" title="From and To Date" [width]="200" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.from_and_toDate}}
                        </ng-template>

                      </kendo-grid-column>
                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price" [width]="150"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-excel fileName="Gas.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>


                </div>

                <!-- } -->
              </cdk-step>
              }
              @if(hasWater) {<cdk-step [optional]="true" data-title="Water">

                <ng-template cdkStepLabel>
                  <span class="number">{{getUtilityIndex('Water')}}</span>
                  <span>Water</span>
                </ng-template>
                <!-- @if(cdkStepper.selectedIndex === 1){ -->
                <div class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="waterGridData" [pageSize]="waterPageSize" [skip]="waterskip" [sortable]="true"
                      [selectable]="selectableSettings" [pageable]="{
                                  buttonCount: buttonCount,
                                  info: info,
                                  type: type,
                                  pageSizes: pageSizes,
                                  previousNext: previousNext
                                }" [reorderable]="true" filterable="menu" [filter]="waterState.filter" [skip]="skip"
                      [sort]="waterState.sort" [columnMenu]="{ filter: true }"
                      (filterChange)="onGasRateFilterChange($event)" (sortChange)="onGasSortChange($event)"
                      [resizable]="true" scrollable="scrollable" (pageChange)="gasUnitRatePageChange($event)"
                      (selectionChange)="gasSelectionChanged($event)" (dataStateChange)="gasDataStateChange($event)"
                      style="width: 100%;">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="gasSearchedValue" (valueChange)="onMeterConfigFilter($event)"></kendo-textbox> -->

                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [(ngModel)]="gasSearchedValue" [clearButton]="true"
                            (valueChange)="onValueChange($event)" (keydown.enter)="onSearchGasUnits()" placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onMeterConfigFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <button class="btn btn-outline-primary btn-sm" (click)="onMeterPdfExport('Excel','water')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>
                      </ng-template>

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                          <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                          </kendo-grid-string-filter-menu>
                          <small style="color:brown">Search is case-sensitive.</small>
                        </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column field="gasValue" title="Gas Value" [width]="100"></kendo-grid-column> -->
                      <kendo-grid-column field="slabName" title="Slab Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="minValue" title="Min Value" [width]="100"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="maxValue" title="Max Value" [width]="100"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="fromDate" title="From Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.fromDate | date: 'dd MMM yyyy'}}
                        </ng-template>

                      </kendo-grid-column>

                      <kendo-grid-column field="toDate" title="To Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span *ngIf="dataItem.toDate; else tillNow">
                            {{ dataItem.toDate | date: 'dd MMM yyyy' }}
                          </span>
                          <ng-template #tillNow>Till now</ng-template>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price" [width]="150"
                        filter="numeric"></kendo-grid-column>

                      <kendo-grid-command-column [columnMenu]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <!-- Info Icon (Clickable) -->
                          <div class="d-flex">
                            <span *ngIf="dataItem?.fileUrl" kendoTooltip title="Download Document" class="ms-2" (click)="downloadFile(dataItem.fileUrl,'
                                  water Document')" style="cursor: pointer; font-size: 18px;">
                              <i class="fa fa-download text-success" aria-hidden="true"></i>
                            </span>

                          </div>
                        </ng-template>
                      </kendo-grid-command-column>
                    </kendo-grid>
                  </div>

                  <!--master download buttons start-->

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid [kendoGridBinding]="mySelectionWater.length>0 ? mySelectionWater:waterGridData.data"
                      #waterGrid>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="slabName" title="Slab Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="minValue" title="Min Value" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="maxValue" title="Max Value" [width]="100"></kendo-grid-column>
                      <!-- <kendo-grid-column field="from" title="From and To Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.fromDate | date: 'dd MMM yyyy' }} -
                          <span *ngIf="dataItem.toDate; else tillNow">
                            {{ dataItem.toDate | date: 'dd MMM yyyy' }}
                          </span>
                          <ng-template #tillNow>Till now</ng-template>
                        </ng-template>
                      </kendo-grid-column> -->

                      <kendo-grid-column field="from_and_toDate" title="From and To Date" [width]="200" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.from_and_toDate}}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price" [width]="150"
                        filter="numeric"></kendo-grid-column>
                      <!-- <kendo-grid-column field="unitPrice" title="Slab Per Unit Price"
                        [width]="150"></kendo-grid-column> -->
                      <kendo-grid-excel fileName="Water.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>


                </div>
                <!-- } -->
              </cdk-step>
              }
              @if(hasElectricity) {<cdk-step [optional]="true" data-title="Electricity">
                <ng-template cdkStepLabel>
                  <span class="number">{{getUtilityIndex('Electricity')}}</span>
                  <span>Electricity</span>
                </ng-template>
                <!-- @if(cdkStepper.selectedIndex === 2){ -->
                <div class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="ElectricityGridData" [pageSize]="electricPageSize" [skip]="electricskip"
                      [sortable]="true" [selectable]="selectableSettings" [pageable]="{
                                                    buttonCount: buttonCount,
                                                    info: info,
                                                    type: type,
                                                    pageSizes: pageSizes,
                                                    previousNext: previousNext
                                                  }" [reorderable]="true" filterable="menu"
                      [filter]="electricState.filter" [skip]="skip" [sort]="electricState.sort"
                      [columnMenu]="{ filter: true }" (filterChange)="onGasRateFilterChange($event)"
                      (sortChange)="onGasSortChange($event)" [resizable]="true" scrollable="scrollable"
                      (pageChange)="gasUnitRatePageChange($event)" (selectionChange)="gasSelectionChanged($event)"
                      (dataStateChange)="gasDataStateChange($event)" style="width: 100%;">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="gasSearchedValue" (valueChange)="onMeterConfigFilter($event)"></kendo-textbox> -->

                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="gasSearchedValue"
                            [clearButton]="true" (valueChange)="onValueChange($event)" (keydown.enter)="onSearchGasUnits()"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onMeterConfigFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <button class="btn btn-outline-primary btn-sm" (click)="onMeterPdfExport('Excel','ele')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>
                      </ng-template>

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                          <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                          </kendo-grid-string-filter-menu>
                          <small style="color:brown">Search is case-sensitive.</small>
                        </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column field="gasValue" title="Gas Value" [width]="100"></kendo-grid-column> -->
                      <kendo-grid-column field="slabName" title="Slab Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="minValue" title="Min Value" [width]="100"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="maxValue" title="Max Value" [width]="100"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="fromDate" title="From Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.fromDate | date: 'dd MMM yyyy'}}
                        </ng-template>

                      </kendo-grid-column>

                      <kendo-grid-column field="toDate" title="To Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span *ngIf="dataItem.toDate; else tillNow">
                            {{ dataItem.toDate | date: 'dd MMM yyyy' }}
                          </span>
                          <ng-template #tillNow>Till now</ng-template>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price" [width]="150"
                        filter="numeric"></kendo-grid-column>


                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <!-- Info Icon (Clickable) -->
                          <div class="d-flex">
                            <span *ngIf="dataItem?.fileUrl" kendoTooltip title="Download Document" class="ms-2"
                              (click)="downloadFile(dataItem.fileUrl, 'Electricity Document')"
                              style="cursor: pointer; font-size: 18px; pointer-events: auto;">
                              <!-- Ensure custom tooltip works -->
                              <i class="fa fa-download text-success" aria-hidden="true"></i>
                            </span>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>



                    </kendo-grid>
                  </div>

                  <!--master download buttons start-->

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionElectric.length>0 ? mySelectionElectric:ElectricityGridData.data"
                      #electGrid>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="slabName" title="Slab Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="minValue" title="Min Value" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="maxValue" title="Max Value" [width]="100"></kendo-grid-column>
                      <!-- <kendo-grid-column field="from" title="From and To Date" [width]="100" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.fromDate | date: 'dd MMM yyyy'}}-{{dataItem.toDate | date: 'dd MMM yyyy'}}
                        </ng-template>
                      </kendo-grid-column> -->

                      <kendo-grid-column field="from_and_toDate" title="From and To Date" [width]="200" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.from_and_toDate}}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price"
                        [width]="150"></kendo-grid-column>
                      <kendo-grid-excel fileName="Electricity.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>


                </div>
                <!-- } -->
              </cdk-step>
              }

              @if(hasEv) {<cdk-step [optional]="true" data-title="EV">
                <ng-template cdkStepLabel>
                  <span class="number">{{getUtilityIndex('EV')}}</span>
                  <span>EV</span>
                </ng-template>
                <!-- @if(cdkStepper.selectedIndex === 2){ -->
                <div class="">
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="EvGridData" [pageSize]="evPageSize" [skip]="evskip" [sortable]="true"
                      [selectable]="selectableSettings" [pageable]="{
                                                    buttonCount: buttonCount,
                                                    info: info,
                                                    type: type,
                                                    pageSizes: pageSizes,
                                                    previousNext: previousNext
                                                  }" [reorderable]="true" filterable="menu"
                      [filter]="electricState.filter" [skip]="skip" [sort]="electricState.sort"
                      [columnMenu]="{ filter: true }" (filterChange)="onGasRateFilterChange($event)"
                      (sortChange)="onGasSortChange($event)" [resizable]="true" scrollable="scrollable"
                      (pageChange)="gasUnitRatePageChange($event)" (selectionChange)="gasSelectionChanged($event)"
                      (dataStateChange)="gasDataStateChange($event)" style="width: 100%;">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="gasSearchedValue" (valueChange)="onMeterConfigFilter($event)"></kendo-textbox> -->

                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="gasSearchedValue"
                            [clearButton]="true" (valueChange)="onValueChange($event)" (keydown.enter)="onSearchGasUnits()"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none" (click)="onMeterConfigFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">
                          <button class="btn btn-outline-primary btn-sm" (click)="onMeterPdfExport('Excel','ev')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>
                      </ng-template>

                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                          <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                          </kendo-grid-string-filter-menu>
                          <small style="color:brown">Search is case-sensitive.</small>
                        </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column field="gasValue" title="Gas Value" [width]="100"></kendo-grid-column> -->
                      <kendo-grid-column field="slabName" title="Slab Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="minValue" title="Min Value" [width]="100"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="maxValue" title="Max Value" [width]="100"
                        filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="fromDate" title="From Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.fromDate | date: 'dd MMM yyyy'}}
                        </ng-template>

                      </kendo-grid-column>

                      <kendo-grid-column field="toDate" title="To Date" [width]="150" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span *ngIf="dataItem.toDate; else tillNow">
                            {{ dataItem.toDate | date: 'dd MMM yyyy' }}
                          </span>
                          <ng-template #tillNow>Till now</ng-template>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price" [width]="150"
                        filter="numeric"></kendo-grid-column>


                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <!-- Info Icon (Clickable) -->
                          <div class="d-flex">
                            <span *ngIf="dataItem?.fileUrl" kendoTooltip title="Download Document" class="ms-2"
                              (click)="downloadFile(dataItem.fileUrl, 'Electricity Document')"
                              style="cursor: pointer; font-size: 18px; pointer-events: auto;">
                              <!-- Ensure custom tooltip works -->
                              <i class="fa fa-download text-success" aria-hidden="true"></i>
                            </span>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>
                    </kendo-grid>
                  </div>

                  <!--master download buttons start-->

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionEv.length>0 ? mySelectionEv:allgasDataList.length>0?allgasDataList:EvGridData.data"
                      #evGrid>
                      <kendo-grid-column field="utilityName" title="Utility Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="slabName" title="Slab Name" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="minValue" title="Min Value" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="maxValue" title="Max Value" [width]="100"></kendo-grid-column>




                      <!-- <kendo-grid-column field="from" title="From and To Date" [width]="100" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.fromDate | date: 'dd MMM yyyy'}}-{{dataItem.toDate | date: 'dd MMM yyyy'}}
                        </ng-template>
                      </kendo-grid-column> -->

                      <kendo-grid-column field="from_and_toDate" title="From and To Date" [width]="200" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.from_and_toDate}}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="unitPrice" title="Slab Per Unit Price"
                        [width]="150"></kendo-grid-column>
                      <kendo-grid-excel fileName="EvReport.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>


                </div>
                <!-- } -->
              </cdk-step>
              }
            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>


<!-- Add UMD modal -->

<!-- Add UMD modal End  -->