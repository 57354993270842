import {
  GetAllElectricityStatus,
  GetAllUmd,
  GetElectricityStatus,
  resetElectricityState,
  resetUmdState,
} from './../../../store/Umd/umd.actions';
import { createutilities, resetUtilitiesState } from './../../../store/Utilities/utilities.actions';
import { Component, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { utilitiesData } from './utilities-data';
import {
  SelectableSettings,
  SelectableMode,
  RemoveEvent,
} from '@progress/kendo-angular-grid';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import Swal from 'sweetalert2';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from './../../../shared/shared.service';
import { LoaderService } from './../../../core/services/loader.service';
import { Store, select } from '@ngrx/store';
import { Validations } from '../../../shared/constants/validations';
import { RpCreateUMDComponent } from '../rp-create-umd/rp-create-umd.component';
import { GetUmd, deleteUmd } from '../../../store/Umd/umd.actions';
import {
  getUmdResponse,
  createUmdResponse,
  getAllUmdResponse,
  GetAllElectricitySuccessResponse,
} from '../../../store/Umd/umd-selector';
import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { SweetAlertIcon, UtilityType } from '../../../shared/constants/enum';
import { Helper } from '../../../core/helpers/helper';
import {
  deleteUmdResponse,
  getElectricitySuccessResponse,
} from '../../../store/Umd/umd-selector';
import {
  getutilitiesResponse,
  selectCreateutilitiesResponse,
} from '../../../store/Utilities/utilities.selector';
import { RpBulkUpoadComponent } from '../../../shared/components/rp-bulkupload/rp-bulkupload.component';
import {
  table_pageSize,
  Templates,
} from '../../../shared/constants/templatesNames';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { getutilities } from '../../../store/Utilities/utilities.actions';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { UmdService } from '../../../core/services/umd.services';
import { url } from 'node:inspector';
import { Subscription, take } from 'rxjs';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { NotificationService } from '../../../shared/notification.service';
@Component({
  selector: 'app-rp-utilities',
  templateUrl: './rp-utilities.component.html',
  styleUrl: './rp-utilities.component.scss',
})
export class RpUtilitiesComponent {
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  selectValue!: string[];
  isAllOpen: boolean = true;

  public waterForm: FormGroup;
  public electricityForm: FormGroup;

  UtilityType = UtilityType;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  private exportUmdSubscription: Subscription = new Subscription();
  private exportElectricitySubscription: Subscription = new Subscription();
  // file upload
  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild('umdGrid') public hiddenUmdGrid: GridComponent | undefined;
  @ViewChild('electricGrid') public hiddenElectricGrid:
    | GridComponent
    | undefined;
  public gridData: unknown[] = utilitiesData;
  public gridView!: unknown[];
  utilitiesForm!: UntypedFormGroup;
  submitted: boolean = false;
  utility_gas_Validations = Validations.gas;
  utility_water_Validations = Validations.water;
  utility_electricity_Validations = Validations.electricity;
  electricityTypeItems: any;

  public pageSize = 10;
  public skip = 0;
  totalMasterCount: number = 0;
  currentPage: number = 1;
  electricitypage: number = 1
  umdList: any[] = [];
  public sort: SortDescriptor[] = [];
  public umdgridData: GridDataResult = { data: [], total: 0 };
  public electric_city_gridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public electricityState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public umd_state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  breadCrumbItems: Array<{}> | undefined;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  mySelectionUmd: any = [];
  myElectricTable_SelectionUmd: any = [];
  currentUser: any;
  projectId: any;
  waterTypeItems: any;
  electricityList: any = [];
  totalElectricityCount: number = 0;
  electricity_pageSize = 10;
  umdOrderByQuery: string = '';
  umdQueryString: string = '';
  umdSearchedValue: any = '';
  electricity_QueryString: string = '';
  electricity_OrderByQuery: string = '';
  electriCitySearch: any = '';
  allUmdList: any[] = [];
  allElectricityList: any[] = [];
  electricityId: any;
  waterID: any;
  gasId: any;
  getUtilitiesData: any;
  configData: any = [];
  isUploading: boolean = false;
  private mcbOnOffSubscription: Subscription;
  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private umdservice: UmdService,
    private notificationService: NotificationService
  ) {
    this.getAllConfigData();
    this.breadCrumbItems = [{ label: 'Utilities' }];

    this.waterForm = this.fb.group({
      water: this.fb.array([this.addWaterGroup()]),
    });

    this.electricityForm = this.fb.group({
      electricity: this.fb.array([this.addElectricityGroup()]),
    });

    this.mcbOnOffSubscription = this.notificationService.mcbTurnOffOnUpdate$.subscribe(data => {
      this.updateMcbOnOffPage(data);
    });
    // this.getStoreInfo();
  }

  public onUpload(): void {
    this.isUploading = true;
  }

  updateMcbOnOffPage(data: any) {
    this.umdSearchedValue = data;
    if (this.umdSearchedValue && this.stepper) {
      this.stepper.selectedIndex = 1;
    }
    this.getAllUmd()
    // Trigger any additional logic needed to refresh the page
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  umdExportAll() {
    this.store.dispatch(resetUmdState());
    this.store.dispatch(
      GetAllUmd({
        pageIndex: 1,
        pageSize: undefined,
        projectId: this.projectId,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
    this.exportUmdSubscription = this.store.pipe(select(getAllUmdResponse)).pipe(take(2)).subscribe({
      next: (res) => {
        if (res && res?.items.length > 0) {
          this.allUmdList = [...res.items];
          const transformedData = this.allUmdList.map((item: any) => ({
            Utility: item?.utility?.name,
            'Supplier Name': item.supplierName,
            'Device Sr No': item.meterSerialNo,
            'MAC Address': item.macAddress,
            'Meter Stats': item.status,
          }));
          this.PdfexportService.downloadExcel(transformedData, 'Umd');
        } else if (res != null) {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.ERROR
          );
        }

        // this.loadUmd();
        console.log('getAllUmdResponse', res, this.umdList);
      }, error: (err) => {

      },
    })
    //this.getAllTotalUmd();
  }
  electricCityExportAll() {
    this.GetAllElectricityStatus();
    this.exportElectricitySubscription = this.store.pipe(select(GetAllElectricitySuccessResponse)).pipe(take(2)).subscribe({
      next: (res) => {
        if (res && res?.items.length > 0) {
          this.allElectricityList = [...res.items];
          const transformedData = this.allElectricityList.map((item: any) => ({
            'MAC Address': item.macAddress,
            'Device Sr No': item.MeterSerialNo,
            'Eb Dg Shift Status': item.ebDgShiftStatus,
            'Eb Dg Shift values': item.ebDgShiftValue,
            Date: Helper.formatDate(item.date),
          }));
          this.PdfexportService.downloadExcel(
            transformedData,
            'Electricity'
          );
        } else if (res != null) {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.ERROR
          );
        }
        console.log('umdList', res, this.allElectricityList);
      },
    })
  }

  getStoreInfo() {
    this.store.dispatch(resetUmdState());
    this.store.dispatch(resetElectricityState());
    let getUtilities$ = this.store.pipe(select(getutilitiesResponse));
    let getUmd$ = this.store.pipe(select(getUmdResponse));
    //let getAllUmdResponse$ = this.store.pipe(select(getAllUmdResponse));
    let createUmdResponse$ = this.store.pipe(select(createUmdResponse));
    let deleteUmdResponse$ = this.store.pipe(select(deleteUmdResponse));

    // let getUtilities$ = this.store.pipe(select(getutilitiesResponse));
    getUtilities$.subscribe((res: any) => {
      this.loaderService.setLoading(false);

      if (res && res.statusCode === 200) {
        this.getUtilitiesData = [];
        this.getUtilitiesData = res.data;

        if (this.utilitiesForm) {
          let gasFile = this.getUtilitiesData?.gas?.gasFile?.fileName
            ? [
              {
                name: this.getUtilitiesData?.gas?.gasFile?.fileName,
                src: this.getUtilitiesData?.gas?.gasFile?.fileUrl,
                size: this.getUtilitiesData?.gas?.gasFile?.fileSize,
                uid: this.getUtilitiesData?.gas?.gasFile?.id,
              },
            ]
            : [];

          let waterFile = this.getUtilitiesData?.water[0]?.waterFile?.fileName
            ? [
              {
                name: this.getUtilitiesData?.water[0]?.waterFile?.fileName,
                src: this.getUtilitiesData?.water[0]?.waterFile?.fileUrl,
                size: this.getUtilitiesData?.water[0]?.waterFile?.fileSize,
                uid: this.getUtilitiesData?.water[0]?.waterFile?.id,
              },
            ]
            : [];

          let electricityFile = this.getUtilitiesData?.electricity[0]?.electricityFile?.fileName
            ? [
              {
                name: this.getUtilitiesData?.electricity[0]?.electricityFile?.fileName,
                src: this.getUtilitiesData?.electricity[0]?.electricityFile?.fileUrl,
                size: this.getUtilitiesData?.electricity[0]?.electricityFile?.fileSize,
                uid: this.getUtilitiesData?.electricity[0]?.electricityFile?.id,
                url: this.getUtilitiesData?.electricity[0]?.electricityFile?.fileUrl,
              },
            ]
            : [];

          if (this.getUtilitiesData?.gas) {
            this.utilitiesForm.patchValue({
              gas: {
                utilityMasterId: this.getUtilitiesData?.gas?.utilityMasterId,
                gasValue: this.getUtilitiesData?.gas?.gasValue,
                UnitLPGValue: this.getUtilitiesData?.gas?.unitLPGValue,
                unitPrice: this.getUtilitiesData?.gas?.unitPrice,
                File: gasFile,
              },
            });
          }
          if (this.getUtilitiesData?.water.length > 0) {
            this.utilitiesForm.patchValue({
              WaterFile: waterFile,
            });
          }
          if (this.getUtilitiesData?.electricity.length > 0) {
            this.utilitiesForm.patchValue({
              ElectricityFile: electricityFile,
            });
          }
          this.utilitiesForm.patchValue({
            projectId: this.getUtilitiesData?.projectId,
            // gas: {
            //   utilityMasterId: this.getUtilitiesData?.gas?.utilityMasterId,
            //   gasValue: this.getUtilitiesData?.gas?.gasValue,
            //   UnitLPGValue: this.getUtilitiesData?.gas?.unitLPGValue,
            //   unitPrice: this.getUtilitiesData?.gas?.unitPrice,
            //   File: gasFile,
            // },
            // WaterFile: waterFile,
            // ElectricityFile: electricityFile,
          });

          // Clear and update the water FormArray
          let waterArray = this.utilitiesForm.get('water') as FormArray;
          waterArray.clear(); // Clear all existing controls

          this.getUtilitiesData?.water.forEach((waterItem: any) => {
            if (waterItem.slabName != null) {
              waterArray.push(
                this.fb.group({
                  id: [waterItem.id],
                  utilityMasterId: [waterItem.utilityMasterId],
                  slabName: [waterItem.slabName, Validators.required],
                  MinValue: [
                    waterItem.minValue,
                    [
                      Validators.required,
                      Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
                    ],
                  ],
                  MaxValue: [
                    waterItem.maxValue,
                    [
                      Validators.required,
                      Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
                    ],
                  ],
                  unitPrice: [
                    waterItem.unitPrice,
                    [
                      Validators.required,
                      Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
                    ],
                  ],
                })
              );
            }
          });

          // Clear and update the electricity FormArray
          let electricityArray = this.utilitiesForm.get('electricity') as FormArray;
          electricityArray.clear(); // Clear all existing controls

          this.getUtilitiesData?.electricity.forEach((electricityItem: any) => {
            if (electricityItem.slabName != null) {
              electricityArray.push(
                this.fb.group({
                  id: [electricityItem.id],
                  utilityMasterId: [electricityItem.utilityMasterId],
                  slabName: [electricityItem.slabName, Validators.required],
                  minKwh: [
                    electricityItem.minKwh,
                    [
                      Validators.required,
                      Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
                    ],
                  ],
                  maxKwh: [
                    electricityItem.maxKwh,
                    [
                      Validators.required,
                      Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
                    ],
                  ],
                  unitPrice: [
                    electricityItem.unitPrice,
                    [
                      Validators.required,
                      Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
                    ],
                  ],
                })
              );
            }
          });
        }
      }

      console.log('responseprofile', res);
    });

    let Createutilitiesuccess$ = this.store.pipe(
      select(selectCreateutilitiesResponse)
    );
    let GetElectricitySuccessStatus$ = this.store.pipe(
      select(getElectricitySuccessResponse)
    );

    let GetAllElectricitySuccessResponse$ = this.store.pipe(
      select(GetAllElectricitySuccessResponse)
    );




    getUmd$.subscribe((res: any) => {
      if (res) {
        this.umdList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalMasterCount = res.totalCount;
      }
      this.loadUmd();
      console.log('umdList', res, this.umdList);
    });

    /*   getAllUmdResponse$.subscribe((res: any) => {
       
      }); */

    Createutilitiesuccess$.subscribe((res: any) => {
      if (res && res.statusCode == 201) {
        // this.utilitiesForm.reset();
        // this.clearValidationStates(this.utilitiesForm);
        this.getUtilities()
      }
      console.log('uuu', res);
    });

    GetElectricitySuccessStatus$.subscribe((res: any) => {
      if (res) {
        this.electricityList = [...res.items];
        this.electricity_pageSize = res.pageSize;
        this.totalElectricityCount = res.totalCount;
      }
      this.loadElectricCity();
      console.log('umdList', res, this.umdList);
    });

    deleteUmdResponse$.subscribe((res: any) => {
      if (res) {
        this.getAllUmd();
      }
      console.log('umdList', res, this.umdList);
    });

    createUmdResponse$.subscribe((res: any) => {
      if (res) {
        this.getAllUmd();
      }
      console.log('umdList', res, this.umdList);
    });
  }



  downloadFile(file: string, fileName: string) {
    this.sharedService.downloadUtilityFiles(file, fileName);
  }
  clearValidationStates(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control) {
        formGroup.reset();
      }
    });
  }

  private loadUmd(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.umdList, state);

    this.umdgridData = {
      data: processedData.data,
      total: this.totalMasterCount,
    };
  }

  getUtilities() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.store.dispatch(getutilities({ projectId: this.projectId }));
  }

  loadElectricCity(): void {
    const state: State = {
      take: this.electricity_pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.electricityList, state);

    this.electric_city_gridData = {
      data: processedData.data,
      total: this.totalElectricityCount,
    };
    this.electric_city_gridData.data = this.electric_city_gridData.data.map(
      (project) => {
        console.log(new Date(project.date));
        return {
          ...project,
          date: new Date(project.date), // Modify the billDate property
        };
      }
    );
  }

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  deleteUtilities() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }
  //Append Fields Set
  private addWaterGroup(): FormGroup {
    return this.fb.group({
      slabName: [],
      min: [],
      max: [],
      slabPerUnit: [],
      unitPrice: [],
    });
  }

  //Add Fields

  //Append Fields Set
  private addElectricityGroup(): FormGroup {
    return this.fb.group({
      slabName: [],
      minKwh: [],
      maxKwh: [],
      unitPrice: [],
    });
  }

  /**
   * Open center modal
   * @param bulkUploadModal
   */
  bulkUploadModal(bulkUploadModal: any) {
    this.modalRef = this.modalService.show(bulkUploadModal);
  }

  /**
   * Open center modal
   * @param addUmdModal center modal data
   */
  addUmdModal(addUmdModal: any) {
    this.modalRef = this.modalService.show(addUmdModal);
  }
  editUmd(data: any) {
    console.log(data);
    let umdData = data;
    let configData = this.configData;
    this.modalRef = this.modalService.show(RpCreateUMDComponent, {
      initialState: { umdData, configData },
      ...this.config,
    });
  }

  deleteUmd(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          this.store.dispatch(deleteUmd({ id: dataItem.id }));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            SweetAlertIcon.INFO
          );
        }
      });
  }


  // onStepChange(event: any): void {
  //   if(this.stepper)
  //   alert(this.stepper.selectedIndex)
  //   this.store.dispatch(resetElectricityState());
  // }

  onStepChange(event: StepperSelectionEvent): void {
    const selectedIndex = event.selectedIndex;
    console.log('Selected step index:', selectedIndex);

    // Perform any action based on the selected index
    if (selectedIndex === 0) {
      this.getUtilities();
      // Code for when step 0 is selected
    }
  }
  ngOnDestroy() {
    if (this.mcbOnOffSubscription) {
      this.mcbOnOffSubscription.unsubscribe();
    }
    this.exportUmdSubscription.unsubscribe();

  }
  ngOnInit(): void {
    this.store.dispatch(resetUtilitiesState());
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    console.log(this.currentUser);

    this.projectId = this.currentUser?.project.id;

    const hasGas = this.isUtilityDisplay('Gas');
    const hasWater = this.isUtilityDisplay('Water');
    const hasElectricity = this.isUtilityDisplay('Electricity');

    this.umdSearchedValue = history.state.info ?? '';


    this.utilitiesForm = this.formBuilder.group({
      projectId: this.projectId,
      gas: this.formBuilder.group({
        utilityMasterId: hasGas ? this.gasId : '',
        gasValue: [
          { value: '', disabled: !hasGas },
          hasGas
            ? [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(20),
              Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
            ]
            : [],
        ],
        UnitLPGValue: [
          { value: '', disabled: !hasGas },
          hasGas
            ? [
              Validators.required,
              Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/),
            ]
            : [],
        ],
        unitPrice: [
          { value: '', disabled: !hasGas },
          hasGas
            ? [
              Validators.required,
              Helper.PatternValidator(/^(?!0(\.0+)?$)(0|[1-9]\d*)(\.\d+)?$/),
            ]
            : [],
        ],
        File: [],
      }),
      water: this.formBuilder.array(hasWater ? [this.createWaterGroup()] : []),
      electricity: this.formBuilder.array(
        hasElectricity ? [this.createElectricityGroup()] : []
      ),
      WaterFile: null,
      ElectricityFile: null,
      removedUtilityIds: this.formBuilder.array([]),
      IsGasFileDeleted: false,
      IsWaterFileDeleted: false,
      IsElectricityFileDeleted: false
    });

    this.utilitiesForm
      .get('gas.gasValue')
      ?.valueChanges.subscribe(() => this.calculateUnitPrice());
    this.utilitiesForm
      .get('gas.UnitLPGValue')
      ?.valueChanges.subscribe(() => this.calculateUnitPrice());
    // this.utilitiesForm.get('gas.unitPrice')?.disable();
    this.getUtilities();

    this.getAllUmd();
    this.GetElectricityStatus();
  }

  public ElectricityStatusValueChange(ev: string): void {

    if (ev.length == 0) {
      this.electricCityPageChange({ skip: 0, take: this.electricity_pageSize })
      this.GetElectricityStatus();
    }
  }

  public UmdValueChange(ev: string): void {

    if (ev.length == 0) {
      this.umdPageChange({ skip: 0, take: this.pageSize })
      this.getAllUmd();
    }
  }

  onSearchUmdDevices(): void {
    if (this.umdSearchedValue.length > 0) {
      this.getAllUmd();
    }
  }




  ngAfterViewInit() {
    if (this.umdSearchedValue && this.stepper) {
      this.stepper.selectedIndex = 1;
    }

  }

  onGasValueBlur(event: any) {
    const value = event.target.value;
    if (value && !isNaN(value)) {
      // Round to two decimal places and update the form control value
      const roundedValue = parseFloat(value).toFixed(2);
      // this.utilitiesForm.controls['gasValue'].patch(roundedValue);
      this.utilitiesForm.patchValue({
        gas: {
          gasValue: roundedValue,
        }
      })
    }
  }

  onWaterValueBlur(event: any, index: number) {
    const inputValue = event.target.value;

    if (inputValue) {
      // Parse the input value to a float and round it to 2 decimal places
      const roundedValue = parseFloat(inputValue).toFixed(3);

      // Set the rounded value back to the form control
      const waterArray = this.waterForm.get('water') as FormArray;
      this.waterArray.at(index).get('unitPrice')?.patchValue(roundedValue);
    }
  }

  isUtilityDisplay(type: string): boolean {
    const utility = this.currentUser.projectUtilities.find(
      (u: any) => u.name === type
    );
    return !!utility; // Returns true if 'Electricity' is found, false otherwise
  }

  private calculateUnitPrice(): void {
    const gasValue = this.utilitiesForm.get('gas.gasValue')?.value;
    const UnitLPGValue = this.utilitiesForm.get('gas.UnitLPGValue')?.value;

    // Check if both values are valid and are not empty strings
    if (gasValue && UnitLPGValue && !isNaN(gasValue) && !isNaN(UnitLPGValue)) {
      // Parse values to ensure they are treated as numbers
      const unitPrice = parseFloat(gasValue) * parseFloat(UnitLPGValue);

      // Log the value and its type to confirm
      console.log(unitPrice + ' ---- ' + typeof unitPrice);

      // If you want to round the result to 2 decimal places
      const roundedUnitPrice = unitPrice.toFixed(2);
      // Ensure the unitPrice is updated correctly
      this.utilitiesForm
        .get('gas.unitPrice')
        ?.setValue(roundedUnitPrice.toString(), { emitEvent: false });
    } else {
      // Reset unitPrice if inputs are invalid or empty
      this.utilitiesForm
        .get('gas.unitPrice')
        ?.setValue('', { emitEvent: false });
    }

    console.log(this.utilitiesForm.value);
  }

  public onUmdFilter(value: any): void {
    this.umdPageChange({ skip: 0, take: this.pageSize })
    this.getAllUmd();
  }

  public onElectricFilter(value: any): void {
    this.electricCityPageChange({ skip: 0, take: this.electricity_pageSize })
    this.GetElectricityStatus();
  }

  GetElectricityStatus() {
    this.store.dispatch(
      GetElectricityStatus({
        pageIndex: this.electricitypage,
        pageSize: this.electricity_pageSize,
        projectId: this.projectId,
        searchTerm: this.electriCitySearch,
        filter: this.electricity_QueryString,
        orderBy: this.electricity_OrderByQuery,
      })
    );
  }

  GetAllElectricityStatus() {
    this.store.dispatch(
      GetAllElectricityStatus({
        pageIndex: this.electricitypage,
        pageSize: undefined,
        projectId: this.projectId,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  getAllUmd() {
    this.store.dispatch(
      GetUmd({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        projectId: this.projectId,
        searchTerm: this.umdSearchedValue,
        filter: this.umdQueryString,
        orderBy: this.umdOrderByQuery,
      })
    );
  }

  getAllTotalUmd() {
    this.store.dispatch(
      GetAllUmd({
        pageIndex: this.currentPage,
        pageSize: undefined,
        projectId: this.projectId,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  createWaterGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: '',
      utilityMasterId: this.waterID,
      slabName: [null, Validators.required],
      MinValue: [
        '',
        [Validators.required, Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/)],
      ],
      MaxValue: [
        '',
        [Validators.required, Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/)],
      ],
      unitPrice: [
        '',
        [
          Validators.required,
          Helper.PatternValidator(/^(?!0(\.0+)?$)(0|[1-9]\d*)(\.\d+)?$/),
        ],
      ],
    });
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((res) => {
      if (
        res.statusCode === 200 &&
        res.message === 'Config data retrieved successfully.'
      ) {
        this.configData = res.data;

        // Filter suppliers based on utility type
        this.electricityTypeItems = res?.data?.suppliers.filter(
          (item: any) => item.master.name === 'Electricity'
        );
        this.waterTypeItems = res?.data?.suppliers.filter(
          (item: any) => item.master.name === 'Water'
        );

        console.log(this.waterTypeItems);


        // Set utility IDs and patch values for respective form groups
        res?.data?.utilities.forEach((item: any) => {
          switch (item?.name) {
            case 'Electricity':
              this.electricityId = item.id;
              this.patchUtilityArray('electricity', this.electricityId);
              break;
            case 'Water':
              this.waterID = item.id;
              this.patchUtilityArray('water', this.waterID);
              break;
            case 'Gas':
              this.gasId = item.id;
              this.utilitiesForm.get('gas')?.patchValue({
                utilityMasterId: this.gasId,
              });
              break;
          }
        });

        console.log(this.utilitiesForm.value);

        // Additional actions after setting the utility IDs
        this.getStoreInfo();
      }
    });
  }

  // Helper method to patch utility ID for form arrays
  private patchUtilityArray(formArrayName: string, utilityMasterId: string): void {
    const formArray = this.utilitiesForm.get(formArrayName) as FormArray;
    formArray.controls.forEach((control: AbstractControl) => {
      (control as FormGroup).patchValue({
        utilityMasterId: utilityMasterId.toString(),
      });
    });
  }


  createElectricityGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      utilityMasterId: this.electricityId,
      slabName: [null, Validators.required],
      minKwh: [
        '',
        [Validators.required, Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/)],
      ],
      maxKwh: [
        '',
        [Validators.required, Helper.PatternValidator(/^(0|[1-9]\d*)(\.\d+)?$/)],
      ],
      unitPrice: [
        '',
        [
          Validators.required,
          Helper.PatternValidator(/^(?!0(\.0+)?$)(0|[1-9]\d*)(\.\d+)?$/),
        ],
      ],
    });
  }

  get gasForm() {
    return this.utilitiesForm.get('gas') as FormGroup;
  }

  get waterArray(): FormArray {
    return this.utilitiesForm.get('water') as FormArray;
  }

  get electricityArray(): FormArray {
    return this.utilitiesForm.get('electricity') as FormArray;
  }

  addWater(): void {
    this.waterArray.push(this.createWaterGroup());
  }

  removeWater(index: number): void {
    const removedUtilityIdsArray = this.utilitiesForm.get(
      'removedUtilityIds'
    ) as FormArray;
    const control = this.waterArray.at(index) as FormGroup;

    // Get the ID from the FormGroup
    const id = control.get('id')?.value;

    // Check if the ID is not null or empty and push to removedUtilityIds
    if (id && id !== '') {
      removedUtilityIdsArray.push(this.formBuilder.control(id));
    }
    this.waterArray.removeAt(index);
  }

  addElectricity(): void {
    this.electricityArray.push(this.createElectricityGroup());
  }

  removeElectricity(index: number): void {
    const removedUtilityIdsArray = this.utilitiesForm.get(
      'removedUtilityIds'
    ) as FormArray;
    const control = this.electricityArray.at(index) as FormGroup;

    // Get the ID from the FormGroup
    const id = control.get('id')?.value;

    // Check if the ID is not null or empty and push to removedUtilityIds
    if (id && id !== '') {
      removedUtilityIdsArray.push(this.formBuilder.control(id));
    }
    this.electricityArray.removeAt(index);
  }

  validateSlabData(
    slabData: any[],
    supplierTypeItems: any[],
    minProp: string,
    maxProp: string
  ): boolean {
    // Create a map to lookup names by their ID
    const nameMap = supplierTypeItems.reduce((map: { [id: string]: string }, item: any) => {
      map[item.id] = item.name;
      return map;
    }, {});

    // Group slabs by slabName
    const slabGroups: { [key: string]: any[] } = {};

    slabData.forEach(slab => {
      const { slabName } = slab;
      if (!slabGroups[slabName]) {
        slabGroups[slabName] = [];
      }
      slabGroups[slabName].push({
        [minProp]: parseFloat(slab[minProp]),
        [maxProp]: parseFloat(slab[maxProp]),
      });
    });

    // Validate each group of slabs with the same slabName
    for (const [slabName, slabs] of Object.entries(slabGroups)) {
      const actualName = nameMap[slabName] || slabName; // Lookup name by ID, fallback to slabName if not found

      if (slabs.length > 1) {
        // Sort slabs by the minimum property to facilitate range checks
        slabs.sort((a, b) => a[minProp] - b[minProp]);

        // Check for same min and max values among duplicates
        const firstSlab = slabs[0];
        const allSameRange = slabs.every(
          slab => slab[minProp] === firstSlab[minProp] && slab[maxProp] === firstSlab[maxProp]
        );
        if (allSameRange) {
          this.sharedService.showMessageDialog(
            `Duplicate slab "${actualName}" with identical ${minProp} and ${maxProp} values.`,
            '',
            SweetAlertIcon.WARNING
          );
          return false;
        }

        // Check for overlapping or adjacent ranges in the sorted slabs
        for (let i = 1; i < slabs.length; i++) {
          const prevSlab = slabs[i - 1];
          const currentSlab = slabs[i];

          if (currentSlab[minProp] >= prevSlab[minProp] && currentSlab[minProp] <= prevSlab[maxProp]) {
            this.sharedService.showMessageDialog(
              `Overlapping or adjacent range detected for slab "${actualName}".`,
              '',
              SweetAlertIcon.WARNING
            );
            return false;
          }
          if (currentSlab[maxProp] >= prevSlab[minProp] && currentSlab[maxProp] <= prevSlab[maxProp]) {
            this.sharedService.showMessageDialog(
              `Overlapping or adjacent range detected for slab "${actualName}".`,
              '',
              SweetAlertIcon.WARNING
            );
            return false;
          }
        }
      }
    }

    // If validation passes
    return true;
  }



  onSave(): void {
    this.submitted = true;

    if (this.utilitiesForm.valid) {

      console.log(this.utilitiesForm.value);

      const isWaterValid = this.validateSlabData(
        this.utilitiesForm.value.water,
        this.waterTypeItems,
        'MinValue',
        'MaxValue'
      );

      if (!isWaterValid) {
        console.log('Water slab data has issues');
        return;
      }

      const isElectricityValid = this.validateSlabData(
        this.utilitiesForm.value.electricity,
        this.electricityTypeItems,
        'minKwh',
        'maxKwh'
      );

      if (!isElectricityValid) {
        console.log('Electricity slab data has issues');
        return;
      }



      this.submitted = false;

      // Get raw form values without mutating
      const utilitiesData = this.utilitiesForm.getRawValue();
      console.log(this.utilitiesForm.getRawValue());

      // Create a mutable clone of the object to work on
      const clonedData = { ...utilitiesData };

      // Extract files from Kendo Upload components
      const gasFileControl = this.utilitiesForm.get('gas.File');
      const electricityFileControl = this.utilitiesForm.get('ElectricityFile');
      const waterFileControl = this.utilitiesForm.get('WaterFile');

      if (
        gasFileControl &&
        gasFileControl.value &&
        gasFileControl.value.length > 0
      ) {
        clonedData.gas.File = gasFileControl.value[0].rawFile; // Extract raw gas file
      }

      if (
        electricityFileControl &&
        electricityFileControl.value &&
        electricityFileControl.value.length > 0
      ) {
        clonedData.ElectricityFile = electricityFileControl?.value[0]?.rawFile; // Extract raw electricity file
      }

      if (
        waterFileControl &&
        waterFileControl.value &&
        waterFileControl.value.length > 0
      ) {
        clonedData.WaterFile = waterFileControl.value[0].rawFile; // Extract raw water file
      }

      console.log(clonedData);

      // return
      this.store.dispatch(
        createutilities({
          utilitiesData: clonedData,
        })

      );
    }
  }

  removeGasFileEventHandler(e: any): void {
    this.utilitiesForm.patchValue({ IsGasFileDeleted: true })
  }
  uploadGasFileEventHandler(e: any): void {
    this.isUploading = true;
    this.utilitiesForm.patchValue({ IsGasFileDeleted: false })
  }
  removeWaterFileEventHandler(e: any): void {
    this.utilitiesForm.patchValue({ IsWaterFileDeleted: true })
  }
  uploadWaterFileEventHandler(e: any): void {
    this.isUploading = true;
    this.utilitiesForm.patchValue({ IsWaterFileDeleted: false })
  }
  removeEleFileEventHandler(e: any): void {
    this.utilitiesForm.patchValue({ IsElectricityFileDeleted: true })
  }
  uploadEleFileEventHandler(e: any): void {
    this.isUploading = true;
    this.utilitiesForm.patchValue({ IsElectricityFileDeleted: false })
  }
  public onSuccess(): void {
    this.isUploading = false;
  }

  add_UMD() {
    let configData = this.configData;
    this.modalRef = this.modalService.show(RpCreateUMDComponent, {
      initialState: { configData },
      ...this.config,
    });
  }
  openBulkUpload() {
    let pageData = { title: Templates.UMD.id, projectId: this.projectId };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });
    // this.modalRef = this.modalService.show(RpUserProfileComponent, { initialState: { profileData }, class: 'modal-lg' });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getAllUmd();
      }
      // Handle the received profile data here
    });
  }

  public onUmdPdfExport(type: string) {
    // if (this.mySelectionUmd.length == 0) {
    //   this.getAllTotalUmd();
    // }
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenUmdGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenUmdGrid?.saveAsPDF();
      }, 1000);
    }
  }
  public onElectricCityPdfExport(type: string) {
    // this.hiddenGrid?.saveAsPDF();
    // if (this.myElectricTable_SelectionUmd.length == 0) {
    //   this.GetAllElectricityStatus();
    // }
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenElectricGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenElectricGrid?.saveAsPDF();
      }, 1000);
    }
  }

  onUmdSortChange(sort: SortDescriptor[]): void {
    this.umd_state.sort = sort;
    console.log(this.umd_state.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.umd_state.sort
    );

    this.umdOrderByQuery = orderByQuery;

    this.getAllUmd();
  }

  public onUmdFilterChange(filter: CompositeFilterDescriptor): void {
    this.umd_state.filter = filter;
    console.log(this.umd_state);
    this.skip = 0
    const queryString = this.sharedService.createQuery(this.umd_state);
    this.umdQueryString = this.sharedService.createQuery(this.umd_state);
    this.getAllUmd();
    console.log(queryString);
  }

  onElectricityFilterChange(filter: CompositeFilterDescriptor): void {
    this.electricityState.filter = filter;
    console.log(this.electricityState);
    this.electricitypage = 1;
    this.skip = 0
    const queryString = this.sharedService.createQuery(this.electricityState);
    this.electricity_QueryString = this.sharedService.createQuery(
      this.electricityState
    );
    this.GetElectricityStatus();
    console.log(queryString);
  }

  onElectricitySortChange(sort: SortDescriptor[]): void {
    this.electricityState.sort = sort;
    console.log(this.electricityState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.electricityState.sort
    );

    this.electricity_OrderByQuery = orderByQuery;

    this.GetElectricityStatus();
  }

  protected umdPageChange({ skip, take }: PageChangeEvent): void {
    this.mySelectionUmd = [];
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAllUmd();
    this.loadUmd();
  }

  protected electricCityPageChange({ skip, take }: PageChangeEvent): void {
    this.myElectricTable_SelectionUmd = [];
    console.log('>>>', skip, take);
    this.skip = skip;
    this.electricity_pageSize = take;
    this.electricitypage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.GetElectricityStatus();
    // this.loadElectricCity();
  }

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionUmd.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionUmd.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionUmd.push(row.dataItem);
      }
    });

    console.log(this.mySelectionUmd);
  }

  public electric_selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.myElectricTable_SelectionUmd.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.myElectricTable_SelectionUmd.splice(index, 1);
      }
    });


    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isElectricRowSelected(row.dataItem)) {
        this.myElectricTable_SelectionUmd.push(row.dataItem);
      }
    });

    console.log(this.myElectricTable_SelectionUmd);
  }

  onSearchElectricCityStatus(): void {
    if (this.electriCitySearch.length > 0) {
      this.GetElectricityStatus();
    }
  }



  public isElectricRowSelected = (rowArg: any) => {
    return this.myElectricTable_SelectionUmd.some(
      (row: any) => row.id === rowArg.id
    );
  };

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionUmd.some((row: any) => row.id === rowArg.id);
  };

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    // this.loadUmd();
  }

  protected electric_dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.electricity_pageSize = state.take;
    this.GetElectricityStatus();
    this.loadElectricCity();
  }
}
