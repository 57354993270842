import { Component, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { DecimalPipe, formatDate } from '@angular/common';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process } from '@progress/kendo-data-query';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { Validations } from '../../../shared/constants/validations';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';

import {
  DragOverEvent,
  NavigateEvent,
  SortableComponent,
} from '@progress/kendo-angular-sortable';
import { SharedService } from '../../../shared/shared.service';
import { ResidentialunitService } from '../../../core/services/residentialunit.service';
import {
  createResidentialUnit,
  getExportResidentialHistory,
  getOccupationHistoryExpotyExcellist,
  getOccupationHistorylist,
  getResidentialHistorylist,
  getResidentialUnitByIdlist,
  reseExportResidentialHistorytState,
  resetOccupaintHistoryState,
  resetResidentialUnitState,
  updateResidentialUnit,
} from '../../../store/residential-unit/residentialUnit.action';

import {
  getExportResidentialHistoryResponse,
  getOccupationHistoryExpotyExcelResponse,
  getOccupationHistoryResponse,
  getResidentialHistoryResponse,
  getResidentialUnitByIdResponse,
} from '../../../store/residential-unit/residentialUnit-selector';
import { take } from 'rxjs';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { State } from '@progress/kendo-data-query';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-rp-create-residential-unit',
  templateUrl: './rp-create-residential-unit.component.html',
  styleUrl: './rp-create-residential-unit.component.scss',
})
export class RpCreateResidentialUnitComponent {
  @ViewChild('sortable') public sortable!: SortableComponent;
  prifileValidations = Validations;
  residentialUnitForm!: UntypedFormGroup;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild('umdpdfGrid') public umdGrid: GridComponent | undefined;
  umdForm!: UntypedFormGroup;
  currentPage: number = 1;
  breadCrumbItems: Array<{}> | undefined;
  lengthOfgas = 'Gas';
  lengthOFwater = 'Water';
  lengthOfelectricity = 'Electricity';
  public selectableSettings!: SelectableSettings;
  currentUserData: any;
  projectId: any;
  billingType: any
  configData: any;
  datePickerConfig: Partial<BsDatepickerConfig> | undefined;
  today: Date;

  minDate: any;
  ocupanyList: any[] = [];
  ownerList: any[] = [];
  isGasOpen: boolean = false;
  isWaterOpen: boolean = false;
  isEleOpen: boolean = false;
  selectedOccupant: any;
  selectedOwner: any;
  ocupantForm!: UntypedFormGroup;
  isOcupantEnable: boolean = false;
  fromDate: any;
  fromDat_vacantDate: any;
  fromDate_moveDate: any;
  searchedOccupant: string = '';
  searchedUmd: string = '';
  submitted: boolean = false;
  occupantSubmitted: boolean = false;
  current_occpanyList: any[] = [];
  umdqueryString: string = '';
  umdorderByQuery: string = '';
  queryString: string = '';
  orderByQuery: string = '';
  public value: Date = new Date(2000, 2, 10);

  modalRef!: BsModalRef;
  modelRef_1!: BsModalRef;
  removeModalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  isCreate = 'create';
  public occupationState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public umdState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  //occupation_History
  public pageSize = 10;
  public umdhistorypageSize = 10
  public skip = 0;
  public umdskip = 0;
  UmdcurrentPage: number = 1;
  occupaintvacantdate: any
  totalUserCount: number = 0;
  umdhistorytotalUserCount: number = 0;
  public residentialunitgridData: GridDataResult = { data: [], total: 0 };
  public residentialunitUmdHistorygridData: GridDataResult = { data: [], total: 0 };

  occupationHistoryList: any[] = [];
  umdexportdata: any[] = []
  exportdata: any[] = []
  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  umdselectedRowdata: any = [];
  umddeselectedRowdata: any = [];
  macAddressEleArray: any[] = [];

  selectedvlaue: any[] = [];
  selectedUtilityType: any;
  selectedUtilitymeter: any[] = [];
  uitilityMetersList: any[] = [];
  electricityList: any[] = [];
  filteredUtilityMeters: any[] = [];
  WaterList: any[] = [];
  gasList: any[] = [];
  utilityTypeList: any[] = [];

  macAddressWaterArray: any[] = [];
  macAddressGasArray: any[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public sort: SortDescriptor[] = [];
  public umdsort: SortDescriptor[] = [];

  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  public umdfilter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  selectValue!: string[];
  isAllOpen: boolean = true;

  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  residentialUniUmdHistoryList: any[] = [];
  private exportSubscription: Subscription = new Subscription();
  private exportumdSubscription: Subscription = new Subscription();
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  billGenerationDate: any
  public gridView!: unknown[];
  updated_residenatilUnit = Object.assign({});
  residenatilUnitByidData: any = {};
  regex = /^(?!.*\s{2,})[a-zA-Z0-9,#-\s]+$/;
  //regex = /^[a-zA-Z0-9,#-]*$/;
  /// regex_Alphanumeric = /^[a-zA-Z0-9]*$/; //Only Alpha numeric are allowed
  regex_Alphanumeric = '^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$'
  regexp = /^[0-9]*(\.[0-9]{0,2})?$/; // "Only Number with 2 decimals allowed

  public checkboxOnly = true;
  public mode: SelectableMode = 'multiple';
  public drag = false;
  billingDate: any;
  ConsumptionDate: any;
  Project_startDate: any
  constructor(
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private router: Router,
    private store: Store,
    private PdfexportService: PdfexportService,
    private residentialunitService: ResidentialunitService
  ) {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };


    if (
      history.state.residentialData != undefined &&
      history.state.residentialData !== null
    ) {
      this.updated_residenatilUnit = history.state.residentialData;

    }
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    console.log(this.currentUserData);
    this.billGenerationDate = this.currentUserData?.billGenerationDate
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
      // this.billingDate = this.currentUserData.project.billingDate;
      this.ConsumptionDate = this.currentUserData.project.ConsumptionDate;
      this.billingType = this.currentUserData.project.billingType;
      this.Project_startDate = this.billingType = this.currentUserData.project.startDate;
    }
    this.today = new Date()
    // this.minDate = new Date('2024-10-01T18:30:00.000Z')
    //let dt = this.billGenerationDate != null ? new Date(this.billGenerationDate) : null;

    if (!this.updated_residenatilUnit?.id) {
      console.log(this.Project_startDate);
      let Project_startDate = this.Project_startDate != null ? new Date(this.Project_startDate) : null;
      this.minDate = Project_startDate != null ? Project_startDate : this.today;
    }

    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format
    }); if (!this.updated_residenatilUnit?.id) {
      console.log(this.Project_startDate);
      const Project_startDate = this.Project_startDate != null ? new Date(this.Project_startDate) : null;
      this.minDate = Project_startDate ?? this.today;  // Using the nullish coalescing operator for better readability
    }

    this.breadCrumbItems = [
      { label: 'Residential unit', url: 'residentialUnit', active: true },
      {
        label: this.updated_residenatilUnit?.id
          ? 'Update residential unit'
          : 'Create residential',
      },
    ];
    this.residentialUnitForm = this.formBuilder.group({
      flat_house: ['', [Validators.required, Validators.pattern(this.regex)]],
      floor: ['', [Validators.required, Validators.pattern(this.regex)]],
      tower: ['', [Validators.required, Validators.pattern(this.regex)]],
      block: ['', [Validators.required, Validators.pattern(this.regex)]],
      Occupant_Name: [],
      currentOccupiedPerson: [''],
      owner_name: [],
      date_registered: [],
      gas_array: this.formBuilder.array([]),
      water_array: this.formBuilder.array([]),
      electricity_array: this.formBuilder.array([]),
    });
    this.ocupantForm = this.formBuilder.group({
      ocupantName: ['', [Validators.required]],
      move_In_Date: [null, [Validators.required]],
      // Vacant_Date: [''],
    });
    this.umdForm = this.formBuilder.group({
      utitlityType: [, Validators.required],
      utitlityMeters: [, Validators.required],
    });
  }
  onDateRangeSelected(event: any): void {
    console.log('Selected date:', event);
    this.selectedDate = event;

    // Handle the selected date here
  }

  getOcccupationHistoryStoreInfo() {
    this.store.dispatch(resetOccupaintHistoryState());
    this.store.dispatch(reseExportResidentialHistorytState());
    let getOccupationHistroy$ = this.store.pipe(select(getOccupationHistoryResponse));
    let getResidentilaUnitById$ = this.store.pipe(select(getResidentialUnitByIdResponse));
    let getResidentialHistoryResponse$ = this.store.pipe(select(getResidentialHistoryResponse))

    getResidentialHistoryResponse$.subscribe((res: any) => {
      if (res) {
        this.residentialUniUmdHistoryList = [...res.items];
        this.umdhistorypageSize = res.pageSize;
        this.umdhistorytotalUserCount = res.totalCount;

        this.loadUmdHistrory();
        console.log(res)
      }
    })

    this.exportumdSubscription = this.store.pipe(select(getExportResidentialHistoryResponse)).subscribe((res: any) => {
      if (res) {

        // console.log(res)
        this.umdexportdata = [];
        if (res.items.length > 0) {
          this.umdexportdata = [...res.items];
          const transformedData = this.umdexportdata.map(
            (item: any) => ({
              'Device Sr.No': item.umdsrno,
              'MAC Address': item.macAddress,
              'Utility': item.type,
              'Initial Reading': item.initialReading,
              'Installed Location': item.installedLocation,
              'Meter Installed Date': item.meterInstallationDate,
              'Meter Initial reading DG KWH': item.initialReadingDGKWH,
              'Meter Initial Reading EB KWH': item.initialReadingEBKWH,
              'Initial Reading Date': item.initialReadingDate,
              'Modified On': new Date(item?.modifiedOn)

            })
          );
          this.PdfexportService.downloadExcel(
            transformedData,
            'umdHistory'
          );
        } else {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.ERROR
          );
        }

      }
    })

    this.exportSubscription = this.store.pipe(select(getOccupationHistoryExpotyExcelResponse)).subscribe((res: any) => {
      if (res) {
        this.exportdata = [];
        if (res.items.length > 0) {
          this.exportdata = [...res.items];
          const transformedData = this.exportdata.map(
            (item: any) => ({
              'Occupant Name': item.name,
              'Move In Date': item.moveInDate,
              'Vacate Date': item.vacateDate
            })
          );
          this.PdfexportService.downloadExcel(
            transformedData,
            'OccupoiantHistory'
          );
        } else {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.ERROR
          );
        }
      }
    })


    // this.residentialunitService.getResidentialUnitById(this.updated_residenatilUnit.id).subscribe((res: any) => {
    getResidentilaUnitById$.pipe(take(2)).subscribe((res: any) => {
      if (res) {
        this.residenatilUnitByidData = {};
        this.residenatilUnitByidData = res;
        this.selectedDate = res.moveInDate
        if (Object.keys(this.residenatilUnitByidData).length > 0) {
          this.isCreate = 'edit';
          //this.isOcupantEnable = true;
          this.isOcupantEnable = this.residenatilUnitByidData.occupant == '' ? false : true
          this.ocupantbuttonDisabled = false;


          if (this.billingType == 'Postpaid') {
            let dt = this.residenatilUnitByidData.billingDate != null ? new Date(this.residenatilUnitByidData.billingDate) : null;
            dt = dt != null ? new Date(dt.setDate(dt.getDate() + 1)) : null;
            console.log(dt);
            this.minDate = dt != null ? dt : this.today
          } else {
            let ConsumptionDate = this.residenatilUnitByidData.consumptionDate != null ? new Date(this.residenatilUnitByidData.consumptionDate) : null;
            ConsumptionDate = ConsumptionDate != null ? new Date(ConsumptionDate.setDate(ConsumptionDate.getDate() + 1)) : null;
            console.log(ConsumptionDate);
            this.minDate = ConsumptionDate != null ? ConsumptionDate : this.today;

          }

          this.residentialUnitForm.patchValue({
            flat_house: this.residenatilUnitByidData?.flatNo
              ? this.residenatilUnitByidData.flatNo
              : '',
            floor: this.residenatilUnitByidData?.floor
              ? this.residenatilUnitByidData.floor
              : '',
            tower: this.residenatilUnitByidData?.tower
              ? this.residenatilUnitByidData.tower
              : '',
            block: this.residenatilUnitByidData?.block
              ? this.residenatilUnitByidData.block
              : '',

            date_registered: this.residenatilUnitByidData?.moveInDate
              ? new Date(this.residenatilUnitByidData.moveInDate)
              : null,
            currentOccupiedPerson: this.residenatilUnitByidData
              ?.currentOccupiedPerson
              ? this.residenatilUnitByidData.currentOccupiedPerson
              : '',
            Occupant_Name: this.residenatilUnitByidData?.occupant
              ? this.residenatilUnitByidData.occupant
              : '',
            owner_name: this.residenatilUnitByidData?.ownerId
              ? this.residenatilUnitByidData.ownerId
              : '',
          });
          this.ownerList?.forEach((item: any) => {
            if (item.id == this.residenatilUnitByidData.ownerId) {
              this.selectedOwner = item;
            }
          });
          console.log(this.selectedOwner)
          let occupant = {
            id: this.residenatilUnitByidData.occupantId,
            name: this.residenatilUnitByidData.occupant,
          };
          this.selectedOccupant = occupant;
          this.residenatilUnitByidData.gas.forEach(
            (item: any, index: number) => {
              this.add_gas_Row(item, index);
            }
          );
          console.log(this.gas_array.length);
          console.log(this.residenatilUnitByidData.gas.length);
          this.residenatilUnitByidData.water.forEach(
            (item: any, index: number) => {
              this.add_water_Row(item, index);
            }
          );
          this.residenatilUnitByidData.electricity.forEach(
            (item: any, index: number) => {
              this.add_electricity_Row(item, index);
            }
          );

          this.residenatilUnitByidData.gas.forEach(
            (item: any, index: number) => {
              console.log(this.formatDate(
                item.meterInstallationDate));
              console.log(this.formatDate(
                item.initialReadingDate));


              let dt = this.residentialUnitForm.controls[
                'gas_array'
              ] as FormArray;
              dt.at(index).patchValue({
                installed_Location: item.installedLocation,
                meterInstallation_Date: this.formatDate(
                  item.meterInstallationDate
                ),
                initialReading: item.initialReading,
                meter_InitialReadingDate: this.formatDate(
                  item.initialReadingDate
                ),
                umdNo: item.umdsrno,
              });
            }
          );

          this.residenatilUnitByidData.electricity.forEach(
            (item: any, index: number) => {
              let dt = this.residentialUnitForm.controls[
                'electricity_array'
              ] as FormArray;
              dt.at(index).patchValue({
                installed_Location: item.installedLocation,
                meterInstallation_Date: this.formatDate(
                  item.meterInstallationDate
                ),
                initialReading: item.initialReading,
                meter_InitialReadingDate: this.formatDate(
                  item.initialReadingDate
                ),
                umdNo: item.umdsrno,
                Meter_Initial_Reading_EB_KWH: item.meterInitialReadingEBKWH,
                Meter_Initial_Reading_DG_KWH: item.meterInitialReadingDGKWH,
              });
            }
          );

          this.residenatilUnitByidData.water.forEach(
            (item: any, index: number) => {
              let dt = this.residentialUnitForm.controls[
                'water_array'
              ] as FormArray;
              dt.at(index).patchValue({
                installed_Location: item.installedLocation,
                meterInstallation_Date: this.formatDate(
                  item.meterInstallationDate
                ),
                initialReading: item.initialReading,
                meter_InitialReadingDate: this.formatDate(
                  item.initialReadingDate
                ),
                umdNo: item.umdsrno,
              });
            }
          );

          // console.log(new Date(this.residenatilUnitByidData.moveInDate))
          //  console.log(this.residenatilUnitByidData);
          // this.ocupantForm.controls['Vacant_Date'].enable();


          this.ocupantForm.patchValue({
            // ocupantName: this.residenatilUnitByidData?.occupantId ? this.residenatilUnitByidData.occupantId : '',
            // move_In_Date: this.formatDate(this.residenatilUnitByidData?.moveInDate ? this.residenatilUnitByidData.moveInDate : ''),
          });
          this.loaderService.setLoading(false);
        }
      }
      console.log('consumerList', res, this.residenatilUnitByidData);
    });

    getOccupationHistroy$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.occupationHistoryList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalUserCount = res.totalCount;
        this.loadGridTable();
      }

      console.log('consumerList', res, this.occupationHistoryList);
    });
  }
  ngOnDestroy() {
    this.exportSubscription.unsubscribe();
    this.exportumdSubscription.unsubscribe();

  }
  loadUmdHistrory() {
    const state: any = {
      take: this.umdhistorypageSize,
      sort: this.umdsort,
      filter: this.umdfilter,
    };

    const processedData = process(this.residentialUniUmdHistoryList, state);
    this.residentialunitUmdHistorygridData = {
      data: this.residentialUniUmdHistoryList,
      total: this.umdhistorytotalUserCount,
    };

    this.residentialunitUmdHistorygridData.data = this.residentialunitUmdHistorygridData?.data.map((item) => {
      // console.log(new Date(project.currentDate));
      return {
        ...item,
        'updatedOn': new Date(item?.modifiedOn)
      };
    });


    this.loaderService.setLoading(false);

  }

  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.occupationHistoryList, state);
    this.residentialunitgridData = {
      data: this.occupationHistoryList,
      total: this.totalUserCount,
    };
    this.loaderService.setLoading(false);
  }

  protected residentialStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }
  protected umdStateChange(state: DataStateChangeEvent): void {
    this.umdsort = state.sort || [];
    this.umdfilter = state.filter || { logic: 'and', filters: [] };
    this.loadUmdHistrory();
  }
  protected OccupationHistory({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = []
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getOccupationHistorylist({
        id: this.updated_residenatilUnit.id,
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: '',
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );

    this.loadGridTable();
  }
  protected umdPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.umdselectedRowdata = []
    this.umdskip = skip;
    this.umdhistorypageSize = take;
    this.UmdcurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getResidentialHistorylist({
        pageIndex: this.UmdcurrentPage,
        pageSize: this.umdhistorypageSize,
        searchTerm: this.searchedUmd,
        id: this.projectId,
        filter: this.umdqueryString,
        orderBy: this.umdorderByQuery,
        residentialunitid: this.updated_residenatilUnit.id
      })
    );


    this.loadGridTable();
  }

  add_gas_Row(meter: any, index: number): void {
    let row = this.formBuilder.group({
      Umd_sR_no: [meter.deviceSrno, Validators.required],
      type: ['Gas', Validators.required],
      installed_Location: [
        '',
        [Validators.required, Validators.pattern(this.regex_Alphanumeric)],
      ],
      meterInstallation_Date: [, [Validators.required]],
      initialReading: [
        '',
        [Validators.required, Validators.pattern(this.regexp)],
      ],
      meter_InitialReadingDate: ['', [Validators.required]],
      umdNo: [meter.id],
    });
    this.macAddressGasArray.push(meter.macAddress);
    this.gas_array.push(row);

    let dt = this.residentialUnitForm.controls['gas_array'] as FormArray;
    let Umd_sR_no = dt.at(index).get('Umd_sR_no')?.disable();
    let type = dt.at(index).get('type')?.disable();
    this.lengthOfgas = `Gas ( ${this.gas_array.length})`;
    if (this.gas_array.length > 0) {
      this.isGasOpen = true;
    }
    //console.log(this.gas_array)
  }
  get gas_array(): FormArray {
    return this.residentialUnitForm.get('gas_array') as FormArray;
  }

  umdonSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.umdselectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.umdselectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.umddeselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.umddeselectedRowdata);
      });

      if (this.umddeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.umddeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.umdselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.umdselectedRowdata = filteredArray;
      }
    }
  }
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  removie_gas(index: number) {
    this.gas_array.removeAt(index);
    this.macAddressGasArray.splice(index, 1);
    this.lengthOfgas =
      this.gas_array.length > 0 ? `Gas ( ${this.gas_array.length})` : 'Gas';
    if (this.gas_array.length == 0) {
      this.isGasOpen = false;
    }
  }
  removie_water(index: number) {
    this.water_array.removeAt(index);
    this.macAddressWaterArray.splice(index, 1);
    this.lengthOFwater =
      this.water_array.length > 0
        ? `Water ( ${this.water_array.length})`
        : 'Water';
    if (this.water_array.length == 0) {
      this.isWaterOpen = false;
    }
  }

  removie_Ele(index: number) {
    this.electricity_array.removeAt(index);
    this.macAddressEleArray.splice(index, 1);
    this.lengthOfelectricity =
      this.electricity_array.length > 0
        ? `Electricity ( ${this.electricity_array.length})`
        : 'Electricity';
    if (this.electricity_array.length == 0) {
      this.isEleOpen = false;
    }
  }

  get occpantControls() {
    return this.ocupantForm.controls;
  }
  get umdFormControls() {
    return this.umdForm.controls;
  }

  add_electricity_Row(meter: any, index: number): void {
    console.log(meter)
    let row = this.formBuilder.group({
      Umd_sR_no: [meter.deviceSrno, Validators.required],
      type: ['Electricity', Validators.required],
      installed_Location: [
        '',
        [Validators.required, Validators.pattern(this.regex_Alphanumeric)],
      ],
      meterInstallation_Date: [, [Validators.required]],
      initialReading: ['', [Validators.pattern(this.regexp)]],
      meter_InitialReadingDate: ['', [Validators.required]],
      Meter_Initial_Reading_EB_KWH: [
        '',
        [Validators.required, Validators.pattern(this.regexp)],
      ],
      Meter_Initial_Reading_DG_KWH: [
        '',
        [Validators.required, Validators.pattern(this.regexp)],
      ],
      umdNo: [meter.id],
    });
    this.macAddressEleArray.push(meter.macAddress);
    this.electricity_array.push(row);

    let dt = this.residentialUnitForm.controls[
      'electricity_array'
    ] as FormArray;
    dt.at(index).get('Umd_sR_no')?.disable();
    dt.at(index).get('type')?.disable();

    console.log('eletcricity->', this.electricity_array.value);
    this.lengthOfelectricity = `Electricity ( ${this.electricity_array.length})`;
    if (this.electricity_array.length > 0) {
      this.isEleOpen = true;
    }
  }
  get electricity_array(): FormArray {
    return this.residentialUnitForm.get('electricity_array') as FormArray;
    //return (this.residentialUnitForm.get('electricity_array') as FormArray);
  }

  add_water_Row(meter: any, index: number): void {
    console.log(meter)
    let row = this.formBuilder.group({
      Umd_sR_no: [meter.deviceSrno, Validators.required],
      type: ['Water', Validators.required],
      installed_Location: [
        '',
        [Validators.required, Validators.pattern(this.regex_Alphanumeric)],
      ],
      meterInstallation_Date: [, [Validators.required]],
      initialReading: [
        '',
        [Validators.required, Validators.pattern(this.regexp)],
      ],
      meter_InitialReadingDate: ['', [Validators.required]],
      umdNo: [meter.id],
    });
    this.macAddressWaterArray.push(meter.macAddress);
    this.water_array.push(row);

    let dt = this.residentialUnitForm.controls['water_array'] as FormArray;
    let Umd_sR_no = dt.at(index).get('Umd_sR_no')?.disable();
    let type = dt.at(index).get('type')?.disable();
    //console.log(Umd_sR_no)

    this.lengthOFwater =
      this.water_array.length > 0
        ? `Water ( ${this.water_array.length})`
        : 'Water';
    if (this.water_array.length > 0) {
      this.isWaterOpen = true;
    }
  }
  get water_array(): FormArray {
    return this.residentialUnitForm.get('water_array') as FormArray;
  }
  selectedDate: any
  filterProjectBillDates: any
  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }



  formatSingleDate(date: Date): string {
    if (!date) return '';

    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  }
  ngOnInit(): void {
    this.ocupantForm.get('move_In_Date')?.valueChanges.subscribe(value => {
      console.log('Selected Date:', value);
    });
    this.isCreate == 'edit'
    // console.log(this.formatDate(this.billGenerationDate))
    // console.log(this.billGenerationDate);


    this.loaderService.setLoading(true);
    this.getAllConfigData();


    this.residentialUnitForm.controls['Occupant_Name'].disable();
    this.residentialUnitForm.controls['date_registered'].disable();
    this.residentialUnitForm.controls['currentOccupiedPerson'].disable();
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.umdState.sort = sort;
    console.log(this.umdState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.umdState.sort
    );

    this.umdorderByQuery = orderByQuery;
    this.store.dispatch(
      getResidentialHistorylist({
        pageIndex: this.UmdcurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedUmd,
        id: this.projectId,
        filter: this.umdqueryString,
        orderBy: this.umdorderByQuery,
        residentialunitid: this.updated_residenatilUnit.id
      })
    );



  }

  onresidentialSortChange(sort: SortDescriptor[]): void {
    this.occupationState.sort = sort;
    console.log(this.occupationState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.occupationState.sort
    );

    this.umdorderByQuery = orderByQuery;
    this.store.dispatch(
      getOccupationHistorylist({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: '',
        id: this.updated_residenatilUnit.id,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  public meterValueChange(ev: string): void {
    if (ev.length == 0) {
      this.store.dispatch(
        getResidentialHistorylist({
          pageIndex: this.UmdcurrentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedUmd,
          id: this.projectId,
          filter: this.umdqueryString,
          orderBy: this.umdorderByQuery,
          residentialunitid: this.updated_residenatilUnit.id
        })
      );
    }
  }
  public occupaintValueChange(ev: string): void {
    if (ev.length == 0) {
      this.store.dispatch(
        getOccupationHistorylist({
          pageIndex: 1,
          pageSize: this.pageSize,
          searchTerm: '',
          id: this.updated_residenatilUnit.id,
          filter: this.queryString,
          orderBy: this.orderByQuery,
        })
      );

    }
  }



  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        let dropdownData: any[] = [];
        let ownerDropdownData: any[] = [];
        this.configData.consumersWithNoRU.forEach((item: any) => {
          dropdownData.push({
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
          });
        });

        this.ocupanyList = [...dropdownData];
        this.configData.consumers.forEach((item: any) => {
          ownerDropdownData.push({
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
          });
        });
        this.ownerList = [...ownerDropdownData];
        /* this.configData.consumers.forEach((item: any) => {
          this.ownerList.push({
            id: item.id,
            name: item.firstName + ' ' + item.lastName,
          });
        }); */
      }
    });

    this.residentialunitService.getProjectUmds(this.projectId).subscribe({
      next: (res) => {
        if (res.statusCode == 200) {
          this.utilityTypeList = res.data.utilities;
          this.electricityList = res.data.utilityMeters.electricity;
          this.WaterList = res.data.utilityMeters.water;
          this.gasList = res.data.utilityMeters.gas;
          this.filteredUtilityMeters ==
            this.electricityList?.concat(this.WaterList, this.gasList);
        }
        console.log(res);
      },
      error: (err) => {
        console.log(err);
      },
    });
    if (Object.keys(this.updated_residenatilUnit).length > 0) {
      this.store.dispatch(
        getOccupationHistorylist({
          pageIndex: 1,
          pageSize: this.pageSize,
          searchTerm: '',
          id: this.updated_residenatilUnit.id,
          filter: this.queryString,
          orderBy: this.orderByQuery,
        })
      );
      this.store.dispatch(
        getResidentialHistorylist({
          pageIndex: this.UmdcurrentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedUmd,
          id: this.projectId,
          filter: this.umdqueryString,
          orderBy: this.umdorderByQuery,
          residentialunitid: this.updated_residenatilUnit.id
        })
      );

      this.store.dispatch(resetResidentialUnitState()); // Optionally reset state
      this.store.dispatch(
        getResidentialUnitByIdlist({ id: this.updated_residenatilUnit.id })
      );
      this.getOcccupationHistoryStoreInfo();
    }
  }
  removeOcupant() {
    this.isOcupantEnable = false;
    this.residentioalControls['owner_name'].clearValidators();
    this.residentioalControls['owner_name'].updateValueAndValidity();

    this.residentialUnitForm.controls['owner_name'].setValue('');
    this.residentialUnitForm.controls['currentOccupiedPerson'].setValue('');
    this.residentialUnitForm.controls['date_registered'].setValue('');
    this.residentialUnitForm.controls['Occupant_Name'].setValue('');
    //this.occpantControls['Vacant_Date'].setValue(this.occupaintvacantdate);
    this.saveResidentionalUnit('Update');
    this.removeModalRef.hide();


  }
  valueChnage_Owner(value: any) {
    this.selectedOwner = value;
    console.log(this.selectedOwner);
    //console.log(this.residentialUnitForm.value);
    //console.log(this.residentialUnitForm.controls['Occupant_Name'].value);
    let filetrdata = this.ocupanyList.filter(
      (item: any) => item.id == value.id
    );
    // console.log(filetrdata)
    if (this.selectedOwner !== undefined && this.selectedOwner.name == this.residentialUnitForm.controls['Occupant_Name'].value) {
      this.residentialUnitForm.controls['currentOccupiedPerson'].setValue('Owner');
    } else {
      this.residentialUnitForm.controls['currentOccupiedPerson'].setValue('Tenant');
    }


    console.log(this.residentialUnitForm.value);
  }

  ocupantbuttonDisabled: boolean = true;
  valueChange_Occupant(value: any): void {
    console.log('valueChange', value);
    this.selectedOccupant = value;
    if (value != undefined && Object.keys(this.selectedOccupant).length > 0) {
      /* this.residentioalControls['owner_name'].setValidators( Validators.required);
      this.residentioalControls['owner_name'].updateValueAndValidity(); */
      //  this.occpantControls['move_In_Date'].setValidators(Validators.required);
      //  this.occpantControls['move_In_Date'].updateValueAndValidity();
      this.ocupantbuttonDisabled = false;
    } else {
      //  this.occpantControls['move_In_Date'].clearValidators();
      //  this.occpantControls['move_In_Date'].updateValueAndValidity();
      //this.ocupantbuttonDisabled = true
    }
  }

  dateChange(event: any): void {
    console.log(event);
    /* this.residentialUnitForm.patchValue({
      Registered_Date: event
    }); */
  }
  isvacantDate: boolean = false;
  vacantdateChange(event: any): void {
    console.log(event);
    // this.isvacantDate =this.ocupantForm.get('Vacant_Date')?.value !== '' ? true : false;

  }
  get residentioalControls() {
    return this.residentialUnitForm.controls;
  }
  OccupantsHistoryonFilter(value: any) {
    this.OccupationHistory({ skip: 0, take: this.pageSize })
  }
  public onFilter(value: any): void {
    this.umdPageChange({ skip: 0, take: this.umdhistorypageSize })
    // const inputValue = value;
    /*  this.store.dispatch(
       getOccupationHistorylist({
         pageIndex: 1,
         pageSize: this.pageSize,
         searchTerm: this.searchedOccupant,
         id: this.updated_residenatilUnit.id,
         filter: this.queryString,
         orderBy: this.orderByQuery,
       })
     ); */
  }
  public umdonFilter(value: any): void {
    // const inputValue = value;

    //  this.umdPageChange({ skip: 0, take: this.umdhistorypageSize })
    this.store.dispatch(
      getResidentialHistorylist({
        pageIndex: this.UmdcurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedUmd,
        id: this.projectId,
        filter: this.umdqueryString,
        orderBy: this.umdorderByQuery,
        residentialunitid: this.updated_residenatilUnit.id
      })
    );
  }




  public umdFilterChange(filter: CompositeFilterDescriptor): void {
    this.umdState.filter = filter;
    console.log(this.umdState);
    this.skip = 0
    const queryString = this.sharedService.createQuery(this.umdState);
    this.umdqueryString = this.sharedService.createQuery(this.umdState);
    this.store.dispatch(
      getResidentialHistorylist({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedUmd,
        id: this.projectId,
        filter: this.umdqueryString,
        orderBy: this.umdorderByQuery,
        residentialunitid: this.updated_residenatilUnit.id
      })
    );
    console.log(queryString);
  }
  public onOccupaintFilterChange(filter: CompositeFilterDescriptor): void {
    this.occupationState.filter = filter;
    console.log(this.occupationState);
    this.skip = 0
    const queryString = this.sharedService.createQuery(this.occupationState);
    this.queryString = this.sharedService.createQuery(this.occupationState);
    this.store.dispatch(
      getOccupationHistorylist({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: '',
        id: this.updated_residenatilUnit.id,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
    console.log(queryString);
  }
  isUtilityDisplay(type: string): boolean {
    const utility = this.currentUserData?.projectUtilities.find(
      (u: any) => u.name === type
    );
    return !!utility; // Returns true if 'Electricity' is found, false otherwise
  }
  saveResidentionalUnit(type: string) {
    this.submitted = true;
    let givenDate = new Date(this.residentialUnitForm.get('date_registered')?.value)
    console.log(this.residentialUnitForm.get('owner_name')?.value)
    // Subtract one day
    let previousDate = new Date(givenDate);
    previousDate.setDate(givenDate.getDate() - 1);

    // Format the result to 'YYYY-MM-DD' format
    let vacateDate = previousDate.toISOString().split('T')[0];
    console.log(this.selectedDate)
    if (this.selectedDate != undefined) {
      const originalDateString = this.selectedDate;
      const originalDate = new Date(originalDateString);
      originalDate.setUTCHours(0, 0, 0, 0);
      const modifiedDateString = originalDate.toISOString();
      console.log(modifiedDateString);
    }


    if (this.residentialUnitForm.valid) {
      var request: any = {
        projectId: this.projectId,
        flatNo: this.residentialUnitForm.get('flat_house')?.value,
        tower: this.residentialUnitForm.get('tower')?.value,
        floor: this.residentialUnitForm.get('floor')?.value,
        block: this.residentialUnitForm.get('block')?.value,
        occupantId: this.selectedOccupant?.id,
        moveInDate: this.selectedDate != null ? this.selectedDate : null,

        //vacateDate: this.updated_residenatilUnit.id != undefined ? vacateDate : null,

        /*  vacateDate:
           this.updated_residenatilUnit.id != undefined
             ? this.ocupantForm.get('Vacant_Date')?.value !== ''
               ? this.ocupantForm.get('Vacant_Date')?.value
               : null
             : null, */
        ownerId: this.residentialUnitForm.get('owner_name')?.value == '' ? null : this.residentialUnitForm.get('owner_name')?.value,
        currentOccupiedPerson: this.selectedOwner?.id ? this.residentialUnitForm.get('currentOccupiedPerson')?.value : 'Owner',
      };
      let electricity: any[] = [];
      let gas: any[] = [];
      let water: any[] = [];
      if (this.electricity_array.length > 0) {
        this.electricity_array.value.forEach((item: any) => {
          electricity.push({
            umdsrno: item.umdNo,
            type: 'electricity',
            installedLocation: item.installed_Location,
            meterInstallationDate: item.meterInstallation_Date,
            meterInitialReadingEBKWH: item.Meter_Initial_Reading_EB_KWH,
            meterInitialReadingDGKWH: item.Meter_Initial_Reading_DG_KWH,
            // initialReading: 0,
            initialReadingDate: item.meter_InitialReadingDate,
          });
        });
      }
      if (this.gas_array.length > 0) {
        this.gas_array.value.forEach((item: any) => {
          gas.push({
            umdsrno: item.umdNo,
            type: 'gas',
            installedLocation: item.installed_Location,
            meterInstallationDate: item.meterInstallation_Date,
            initialReading: item.initialReading,
            initialReadingDate: item.meter_InitialReadingDate,
          });
        });
      }
      if (this.water_array.length > 0) {
        this.water_array.value.forEach((item: any) => {
          water.push({
            umdsrno: item.umdNo,
            type: 'Water',
            installedLocation: item.installed_Location,
            meterInstallationDate: item.meterInstallation_Date,
            initialReading: item.initialReading,
            initialReadingDate: item.meter_InitialReadingDate,
          });
        });
      }
      (request.gas = gas),
        (request.water = water),
        (request.electricity = electricity),


        console.log(request);
      if (this.updated_residenatilUnit && this.updated_residenatilUnit?.id) {
        if (this.selectedOccupant && this.selectedOccupant.id !== this.updated_residenatilUnit.occupantId) {
          request.vacateDate = vacateDate
        }
      }
      if (type === 'Create') {
        this.store.dispatch(
          createResidentialUnit({ ResidentialUnit: request })
        );
      } else {
        this.store.dispatch(
          updateResidentialUnit({
            ResidentialUnit: request,
            id: this.residenatilUnitByidData.id,
          })
        );
      }
    }
  }

  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  saveOcupant() {
    //console.log(this.residentialUnitForm.get('owner_name')?.value)
    // if (this.residentialUnitForm.get('owner_name')?.value !== null) {
    this.occupantSubmitted = true;
    // console.log(this.ocupantForm.value);
    // console.log(this.selectedDate)

    /*  console.log(this.formatDate(
       this.ocupantForm.get('move_In_Date')?.value
     )) */


    if (this.ocupantForm.valid) {
      this.residentialUnitForm.patchValue({
        date_registered:
          this.ocupantForm.get('move_In_Date')?.value,

        // Occupant_Name: this.ocupantForm.get('ocupantName')?.value.name,
        Occupant_Name: this.selectedOccupant.name,
      });

      // console.log(this.residentialUnitForm.controls['Occupant_Name'].value)
      if (this.residentialUnitForm.get('owner_name')?.value !== null) {
        if (this.selectedOwner !== undefined && this.selectedOwner.name == this.residentialUnitForm.controls['Occupant_Name'].value) {
          this.residentialUnitForm.controls['currentOccupiedPerson'].setValue('Owner');
        } else {
          this.residentialUnitForm.controls['currentOccupiedPerson'].setValue('Tenant');
        }
      } else {
        this.residentialUnitForm.controls['currentOccupiedPerson'].setValue('Tenant');
      }
      this.isOcupantEnable = true; // to show Occuant from feilds
      this.modalService.hide();
      this.modalRef.hide();
      console.log(this.residentialUnitForm);
    }
    /*  } else {
 
       this.sharedService.showMessageDialog(
         'Please Select Owner',
         '',
         SweetAlertIcon.ERROR
       );
 
     } */
  }

  save() {
    this.modalRef.hide();
  }
  eleError: boolean = false;
  umdSubmit: boolean = false;
  saveUmd() {
    this.umdSubmit = true;
    console.log(this.umdForm);

    if (this.umdForm.valid) {
      switch (this.selectedUtilityType) {
        case 'Electricity':
          this.selectedUtilitymeter.forEach((x: any, index: number) => {
            if (!this.macAddressEleArray?.includes(x.macAddress)) {
              this.add_electricity_Row(x, index);
              this.eleError = false;
            } else {
              this.eleError = true;
            }
          });
          break;
        case 'Gas':
          this.selectedUtilitymeter.forEach((x: any, index: number) => {
            if (!this.macAddressGasArray?.includes(x.macAddress)) {
              this.add_gas_Row(x, index);
              this.eleError = false;
            } else {
              this.eleError = true;
            }
          });

          break;
        case 'Water':
          this.selectedUtilitymeter.forEach((x: any, index: number) => {
            if (!this.macAddressWaterArray?.includes(x.macAddress)) {
              this.add_water_Row(x, index);
              this.eleError = false;
            } else {
              this.eleError = true;
            }
          });
          break;
      }
      this.modelRef_1.hide();
    }
  }

  openOccupant(template: any) {
    this.isvacantDate = false;
    this.occupantSubmitted = false;
    console.log(this.ocupantForm.controls['move_In_Date'].value)
    this.ocupantForm.controls['move_In_Date'].setValue('');
    //this.ocupantForm.controls['Vacant_Date'].setValue('');
    this.ocupantForm.controls['ocupantName'].setValue([]);
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  removedOccupant(template: any) {
    this.removeModalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  utilityChange(event: any) {
    console.log(event);
    this.selectedUtilityType = event;
    this.selectedvlaue = [];
    switch (event) {
      case 'Electricity':
        if (this.macAddressEleArray.length > 0) {
          this.uitilityMetersList = this.electricityList.filter(
            (x: any) => !this.macAddressEleArray?.includes(x.macAddress)
          );
        } else {
          this.uitilityMetersList = this.electricityList;
        }
        break;
      /* this.uitilityMetersList = this.gasList.filter(
        (x: any) => !this.macAddressEleArray?.includes(x.macAddress)
      ); */


      case 'Water':
        if (this.macAddressWaterArray.length > 0) {
          this.uitilityMetersList = this.WaterList.filter(
            (x: any) => !this.macAddressWaterArray?.includes(x.macAddress)
          );
        } else {
          this.uitilityMetersList = this.WaterList;
        }
        /* this.uitilityMetersList = this.gasList.filter(
          (x: any) => !this.macAddressWaterArray?.includes(x.macAddress)
        ); */

        break;
      case 'Gas':
        if (this.macAddressGasArray.length > 0) {
          this.uitilityMetersList = this.gasList.filter(
            (x: any) => !this.macAddressGasArray?.includes(x.macAddress)
          );
        } else {
          this.uitilityMetersList = this.gasList;
        }
        /*  this.uitilityMetersList = this.gasList.filter(
           (x: any) => !this.macAddressGasArray?.includes(x.macAddress)
         ); */


        break;
    }
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  public umdshowTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  utilityMeterChange(event: any) {
    console.log(event);
    this.selectedUtilitymeter = [];
    this.selectedUtilitymeter = event;
  }
  /* tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  } */

  openUmd(addUmd: any) {
    this.umdSubmit = false;
    this.uitilityMetersList = [];
    this.selectedUtilityType = '';
    this.selectedvlaue = [];
    this.modelRef_1 = this.modalService.show(addUmd, {
      class: 'modal-dialog-centered',
    });
  }

  selectedIndex: number = 2
  tabChange(event: any) {
    this.selectedIndex = event.selectedIndex;
    console.log(event.selectedIndex);
    console.log('Previous step index:', event.previouslySelectedIndex);
  }
  exportAll() {
    this.store.dispatch(
      getOccupationHistoryExpotyExcellist({
        pageIndex: 1,
        pageSize: this.totalUserCount,
        searchTerm: '',
        id: this.updated_residenatilUnit.id,
        filter: this.queryString,
        orderBy: this.orderByQuery,

      })
    );
    //this.getAllResidentialUnitlist();
  }

  umdExportAll() {
    this.store.dispatch(
      getExportResidentialHistory({
        pageIndex: 1,
        pageSize: this.umdhistorytotalUserCount,
        searchTerm: '',
        id: this.projectId,
        filter: this.umdqueryString,
        orderBy: this.umdorderByQuery,

      })
    );
  }
  /* public onExport() {
    this.hiddenGrid?.saveAsPDF();
  } */
  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } /* else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    } */
  }
  public umdonExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.umdGrid?.saveAsExcel();
      }, 1000);
    }
  }

  editMeter(dataItem: any) {

  }

  deleteMeter(dataItem: any) { }










}
