import { formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Component, ViewChild } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { invoiceData } from './invoice-data';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { RemoveEvent } from '@progress/kendo-angular-upload';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import {
  createInvoiceResponse,
  deleteInvoiceResponse,
  getAllBillinvloiceResponse,
  getBillinvloiceResponse,
  updateInvoiceResponse,
} from '../../../store/billsSettings/billSettings-selector';
import {
  createInvoice,
  deleteInvoice,
  getAllBillinvloicelist1,
  getBillinvloicelist1,
  resetInvoiceState,
  updateInvoice,
} from '../../../store/billsSettings/billSettings.action';
import { SharedService } from '../../../shared/shared.service';
import { Validations } from '../../../shared/constants/validations';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { process, State } from '@progress/kendo-data-query';
import {
  slop_position,
  table_pageSize,
} from '../../../shared/constants/templatesNames';
import { Helper } from '../../../core/helpers/helper';
import { FileInfo } from '@progress/kendo-angular-upload';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SweetAlertIcon } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-invoice',
  templateUrl: './rp-invoice.component.html',
  styleUrl: './rp-invoice.component.scss',
})
export class RpInvoiceComponent {
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png'],
  };
  datePickerConfig: Partial<BsDatepickerConfig>;
  public mySelection: any = [];
  invoiceAddForm!: UntypedFormGroup;
  public fileImages: Array<FileInfo> | undefined;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  orderByQuery: string = '';
  queryString: string = '';
  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  invoicesearchedValue = '';
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public billsInvoice: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };


  public billInvoicegridData: GridDataResult = { data: [], total: 0 };
  billInvoiceList: any[] = [];
  billsInvoicetotalUserCount: number = 0;

  public pageSize = 10;
  public skip = 0;

  prifileValidations = Validations;
  public pageSizes = table_pageSize.pageSizes;
  public slop_position = slop_position;
  modelRef_1!: BsModalRef;
  invoiceAdd!: BsModalRef;

  isAllOpen: boolean = true;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  url: any = '';
  hidden!: boolean;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  public billpageSizes = true;
  public billpreviousNext = true;

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';

  submitted: boolean = false;
  optionalSubmitted: boolean = false;
  individualbillSubmitted: boolean = false;
  searchedValue: any = '';
  configData: any;
  utilityList: any[] = [];

  selectedUtilityType: any[] = [];
  regex = /^[0-9]*$/;
  gstregex: RegExp = /^(1?[0-9]|2[0-8])$/;

  regex_Alphanumeric = /^[a-zA-Z0-9]*$/; //Only Alpha numeric are allowed
  NumberWithDecimalregex: RegExp = /^\d+(\.\d+)?$/;
  Onlynumberwithdecimals = /^\d+(\.\d+)?$/; //Only number with decimals

  public gridDataInvoice: unknown[] = invoiceData;

  @ViewChild('dp', { static: true }) datePicker: any;

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  billGenType?: string;
  billAllInvoiceList: any[] = [];
  breadCrumbItems: Array<{}> | undefined;

  constructor(
    private modalService: BsModalService,
    private sharedService: SharedService,
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private PdfexportService: PdfexportService
  ) {
    this.breadCrumbItems = [{ label: 'Invoices Ads' }];
    this.datePickerConfig = {
      dateInputFormat: 'DD-MM-YYYY',  // Set the format to dd-mm-yyyy
      isAnimated: true,  // Optional: add animation
      showWeekNumbers: false,  // Optional: hide week numbers
    };
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };

    this.invoiceAddForm = this.formBuilder.group({
      slot: ['', [Validators.required, Validators.pattern(this.regex)]],
      date: ['', [Validators.required]],
      files: [this.fileImages, [Validators.required]],
      projectId: ['', [Validators.required]],
    });

    this.getAllConfigData();
    this.getInvoiceStoreInfo();
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  getAllConfigData() {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        this.utilityList = this.configData.utilities;
      }
    });
  }

  isUploading: boolean = false;

  get invoiceAddFormFormControls() {
    return this.invoiceAddForm.controls;
  }
  public onUpload(): void {
    this.isUploading = true;
  }

  public onSuccess(): void {
    this.isUploading = false;
  }
  private exportSubscription: Subscription = new Subscription();
  getInvoiceStoreInfo() {
    let updateInvoiceResponse$ = this.store.pipe(select(updateInvoiceResponse));
    let getInvoiceAds$ = this.store.pipe(select(getBillinvloiceResponse));
    let deleteInvoiceResponse$ = this.store.pipe(select(deleteInvoiceResponse));

    let createInvoiceResponse$ = this.store.pipe(select(createInvoiceResponse));

    this.exportSubscription = this.store
      .pipe(select(getAllBillinvloiceResponse))
      .subscribe((res: any) => {
        if (res) {
          this.billAllInvoiceList = [];
          if (res.items.length > 0) {
            this.billAllInvoiceList = [...res.items];

            const transformedData = this.billAllInvoiceList.map((item: any) => {
              const fromDate = new Date(Helper.excelformatDate(item.from));
              const toDate = new Date(Helper.excelformatDate(item.to));

              // Calculate the difference in milliseconds
              const differenceInMilliseconds = toDate.getTime() - fromDate.getTime();

              // Convert to days (or other units)
              const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

              return {
                'Project': item.project.name,// Show as an integer number of days,
                // 'From to date': `${Helper.excelformatDate(item.from)} - ${Helper.excelformatDate(item.to)}`,
                'From date': `${Helper.excelformatDate(item.from)}`,
                'To date': ` ${Helper.excelformatDate(item.to)}`,
                'Slot Position': item.slot,
                // Image: item.fileUrl,
              };
            });

            this.PdfexportService.downloadExcel(transformedData, 'invoices');
          }
          else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
          console.log('billallSettingList', res, this.billAllInvoiceList);
        }
      });

    getInvoiceAds$.subscribe((res: any) => {

      if (res) {
        this.loaderService.setLoading(false);
        this.billInvoiceList = [...res.items];
        this.pageSize = res.pageSize;
        this.billsInvoicetotalUserCount = res.totalCount;
        this.loadBillsInvoiceGridTable();
      }
      console.log('billSettingList', res, this.billInvoiceList);
    });

    updateInvoiceResponse$.subscribe((res: any) => {
      if (res) {
        console.log('res', res);
        this.getInvoiceList();
        this.invoiceAdd.hide();
      }
    });

    createInvoiceResponse$.subscribe((res: any) => {
      if (res) {
        console.log('res', res);
        this.getInvoiceList();
        this.invoiceAdd.hide();
      }
    });

    deleteInvoiceResponse$.subscribe((res: any) => {
      if (res) {
        console.log('res', res);
        this.getInvoiceList();
        this.invoiceAdd.hide();
      }
    });


  }

  ngOnDestroy() {
    this.store.dispatch(resetInvoiceState());
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  // convertImageToBase64(imageUrl: string): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     let img = new Image();
  //     img.crossOrigin = 'Anonymous';
  //     img.src = imageUrl;
  //     img.onload = function () {
  //       let canvas = document.createElement('canvas');
  //       canvas.width = img.width;
  //       canvas.height = img.height;
  //       let ctx = canvas.getContext('2d');
  //       ctx!.drawImage(img, 0, 0);
  //       let dataURL = canvas.toDataURL('image/png');
  //       resolve(dataURL);
  //     };
  //     img.onerror = function () {
  //       reject('Could not convert image to base64');
  //     };
  //   });
  // }
  loadBillsInvoiceGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.billInvoiceList, state);
    this.billInvoicegridData = {
      data: this.billInvoiceList,
      total: this.billsInvoicetotalUserCount,
    };

    this.billInvoicegridData.data = this.billInvoicegridData.data.map(
      (invoice) => {
        console.log(new Date(invoice.date));
        return {
          ...invoice,
          'from': `${Helper.excelformatDate(invoice.from)} `,
          'to': ` ${Helper.excelformatDate(invoice.to)}` // Modify the billDate property
        };
      }
    );
    // this.billInvoicegridData = {
    //   data: this.billInvoiceList.map((item: any) => {
    //     return {
    //       ...item,
    //       // Add 'fileUrl' for each item (custom logic here for the URL)
    //       fileImg: item.fileUrl?this.convertImageToBase64(item.fileUrl):'' // Assuming you have a method to generate the file URL
    //     };
    //   }),
    //   total: this.billsInvoicetotalUserCount,
    // };
    console.log(this.billInvoicegridData);
    // this.loaderService.setLoading(false);
  }

  private files: any[] = [];
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      // this.file = event.target.files[0]
      var fromdate = new FormData();
      //this.invoiceAddForm.patchValue({ image: this.file });
      // fromdate.append('file', file);

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event?.target?.result;
        console.log(this.url);
      };
    }
  }
  onFilter(value: any) {
    this.invoicePageChange({ skip: 0, take: this.pageSize });
    this.getInvoiceList();
  }
  deleteInvoice() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  utilityChange(event: any) {
    console.log(event);
  }
  /**
   * Advertisment modal
   * @param addAdvertismentModal Advertisment modal data
   */
  showAdvertismentModal(addAdvertismentModal: any) {
    this.modalRef = this.modalService.show(addAdvertismentModal);
  }

  /**
   * Charge modal
   * @param addChargeModal Advertisment modal data
   */
  showChargeModal(addChargeModal: any) {
    this.modalRef = this.modalService.show(addChargeModal);
  }
  currentUserData: any;
  projectId: any;
  ngOnInit(): void {
    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.getInvoiceList();
  }
  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.invoicePageChange({ skip: 0, take: this.pageSize });
      this.getInvoiceList();
    }
  }

  onSearchInvoicesAds():void{
    if(this.invoicesearchedValue.length>0){
      this.getInvoiceList()
    }
  }



  getInvoiceList() {
    this.store.dispatch(
      getBillinvloicelist1({
        pageIndex: this.invoicecurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.invoicesearchedValue,
        orderBy: this.orderByQuery,
        filter: this.queryString,
      })
    );
  }

  exportAll() {
    this.getAllInvoiceList();
  }
  public onExport(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /* if (this.mySelection.length == 0) {
      this.getAllInvoiceList();
    } else {
      if (type === 'Excel') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else if (type === 'Pdf') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsPDF();
        }, 1000);
      }
    } */
  }

  getAllInvoiceList() {
    this.store.dispatch(
      getAllBillinvloicelist1({
        projectId: this.invoiceAddForm.value.projectId,
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }
  public imageSrc: string | ArrayBuffer | null = null;
  handleFileSelected(event: any) {
    console.log(event);
    this.files = [];
    const newFiles = event.files.filter(
      (f: any) =>
        !this.files.find((existingFile) => existingFile.name == f.name)
    );
    this.files = [...this.files, ...newFiles];
    if (this.files) {
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(event.files[0].rawFile);
    }

    console.log(this.files);
  }
  public onRemove(event: RemoveEvent): void {
    console.log('File removed:', event.files);
    this.imageSrc = null;
    // You can add additional logic here, such as notifying the server or updating your UI state
  }
  handleFileRemoved(event: any) {
    console.log(event);
  }
  invoiceId: any;
  saveinvoiceAdd(type: string) {
    this.individualbillSubmitted = true;
    console.log(this.invoiceAddForm.value);
    if (this.invoiceAddForm.invalid) {
      return;
    } else {
      if (type == 'Create') {
        this.store.dispatch(
          createInvoice({
            Invoice: this.invoiceAddForm.value,
            projectId: this.invoiceAddForm.value.projectId,
          })
        );
        this.invoiceAdd.hide();
      } else {
        this.store.dispatch(
          updateInvoice({
            UpdateInvoice: this.invoiceAddForm.value,
            id: this.invoiceId,
            projectId: this.invoiceAddForm.value.projectId,
          })
        );
        this.invoiceAdd.hide();
      }
    }
  }

  deletdinvoiceAdd(dataItem: any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(deleteInvoice({ id: dataItem.id }));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }
  valueChange(value: any) {
    console.log(this.invoiceAddForm.controls['slot'].value);
    console.log(value);
  }
  selectedSlot: any;
  editInvoice(data: any, addInvoiceModal: any) {
    this.invoiceId = data.id;
    let url = [
      {
        name: data.fileName,
        src: data.fileUrl,
        size: 1000,
        uid: 1,
      },
    ];
    this.imageSrc = url[0].src; // image preview upadted
    let dt = [];
    dt.push(new Date(data.from));
    dt.push(new Date(data.to));
    let slot_id = data.slot;
    //console.log(data.slot.)
    this.invoiceAddForm.controls['slot'].setValue(data.slot);
    this.invoiceAddForm.patchValue({
      date: dt,
      // slot: slot_id,
      projectId: data.project.id,
      files: url,
    });

    // console.log(this.invoiceAddForm.value);
    this.invoiceAdd = this.modalService.show(addInvoiceModal, {
      class: 'modal-dialog-centered',
    });
  }
  invoiceselectedRowdata: any = [];
  invoicedeselectedRowdata: any = [];

  public isRowSelected = (rowArg: any) => {
    return this.mySelection.some((row: any) => row.id === rowArg.id);
  };
  invoiceSelectforBillSetting(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelection.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelection.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isRowSelected(row.dataItem)) {
        this.mySelection.push(row.dataItem);
      }
    });

    console.log(this.mySelection);
  }
  invoicecurrentPage: number = 1;
  protected invoicePageChange({ skip, take }: PageChangeEvent): void {
    this.mySelection = [];
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.invoicecurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getInvoiceList();
    this.loadBillsInvoiceGridTable();
  }

  protected invoicetateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadBillsInvoiceGridTable();
  }

  opemInvoiceModel(addInvoiceModal: any) {
    this.individualbillSubmitted = false;
    this.imageSrc = null;
    this.invoiceId = '';
    this.invoiceAddForm.reset();
    this.invoiceAdd = this.modalService.show(addInvoiceModal, {
      class: 'modal-dialog-centered',
    });
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.billsInvoice.sort = sort;
    console.log(this.billsInvoice.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.billsInvoice.sort
    );
    this.orderByQuery = orderByQuery;

    this.getInvoiceList();
  }

  public onInvoiceFilterChange(filter: CompositeFilterDescriptor): void {
    this.billsInvoice.filter = filter;
    console.log(this.billsInvoice);
    const queryString = this.sharedService.createQuery(this.billsInvoice);
    this.queryString = this.sharedService.createQuery(this.billsInvoice);
    this.getInvoiceList();
    console.log(queryString);
  }
}
