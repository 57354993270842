<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>

        </span>

      </div>
    </div>
  </div>

  <!-- <div class="row">
    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
    
  </div> -->

  <div class="row">
    <div class="col-lg-12">
      <form>
        <div class="row">
          <div class="col-lg-4">
            <div class="mb-3">
              <label class="form-label">From Date -To Date </label>
              <!-- <input type="text" class="form-control date" [(ngModel)]="fromDate" (bsValueChange)="selectDates($event)"
                name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig" /> -->

              <div class="position-relative">
                <input type="text" class="form-control date" [value]="fromDate ? (formatDateRange(fromDate)) : ''"
                  placeholder="Select Date" name="daterange" autocomplete="off" bsDaterangepicker
                  [bsConfig]="datePickerConfig" [(bsValue)]="fromDate" />
                <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                <!-- Clear icon -->
                <span class="bx bx-x inputIcon clear-icon" *ngIf="fromDate.length>0" (click)="clearDate()"></span>
              </div>


            </div>
          </div>
          <div class="col-lg-2 d-flex align-items-center">
            <button class="btn btn-primary btn-md mt-2" (click)="filterBasedOnDate(1)">Filter</button>
            <button class="btn btn-secondary btn-md mt-2 ms-2" (click)="filterBasedOnDate(0)">Clear</button>
          </div>
        </div>
      </form>

    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <section class="p-2">
            <ng-template #template let-anchor>
              <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>
            <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
              (mouseover)="showTooltip($event)">
              <kendo-grid [data]="consmergridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                [selectable]="selectableSettings" scrollable="scrollable" [pageable]="{
                                                buttonCount: buttonCount,
                                                info: info,
                                                type: type,
                                                pageSizes: pageSizes,
                                                previousNext: previousNext
                                              }" [reorderable]="true" filterable="menu"
                [filter]="consumerRechargesState.filter" [skip]="skip" [sort]="consumerRechargesState.sort"
                [columnMenu]="{ filter: true }" (filterChange)="onrechargeFilterChange($event)"
                (sortChange)="onSortChange($event)" [resizable]="true" (pageChange)="rechargePageChange($event)"
                (selectionChange)="onSelect($event)" (dataStateChange)="consumerStateChange($event)">


                <ng-template kendoGridToolbarTemplate>
                  <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                    [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->

                  <!-- <div class="search_icon">
                    <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="searchedValue"
                      placeholder="Search in all columns...">
                    </kendo-textbox>

                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                      <i class="fa fa-search"></i>
                    </button>
                  </div> -->

                  <div class="search-container">
                    <kendo-textbox [(ngModel)]="searchedValue" [clearButton]="true"
                      placeholder="Search in all columns..." [style.width.px]="250" [style.height.px]="35"
                      (valueChange)="onValueChange($event)" (keydown.enter)="onSearchRechargeHistory()">
                    </kendo-textbox>

                    <!-- Clear Icon inside the TextBox -->
                    <!--   <span *ngIf="searchedValue" class="clear-icon" (click)="clearSearch()">&times;</span> -->

                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>

                  <kendo-grid-spacer></kendo-grid-spacer>
                  <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="onExport('Excel')">
                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                  </button>

                  <!--   <button class="btn btn-light" (click)="onExport('Pdf')">
                  <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                </ng-template>


                <!-- Pdf   start-->
                <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                  <kendo-grid [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:consmergridData.data"
                    #pdfGrid>
                    <kendo-grid-column field="date" title="Date" format="{0:dd MMM yyyy HH:mm:ss}"></kendo-grid-column>
                    <kendo-grid-column field="rechargeAmount" title="Recharge Amount"></kendo-grid-column>
                    <kendo-grid-column field="currentBalance" title="Current Balance"></kendo-grid-column>
                    <!--  <kkendo-grid-column field="currentBalance" title="Current Balance"
                      filter="numeric"></kkendo-grid-column> -->
                    <kendo-grid-column field="paymentRefNumber" title="Payment Ref No">
                    </kendo-grid-column>
                    <kendo-grid-column field="mode" title="Mode of Payment">
                    </kendo-grid-column>
                    <kendo-grid-column field="rechargeStatus" title="Recharge Status">
                    </kendo-grid-column>

                    <kendo-grid-excel fileName="rechargeHistory.xlsx"></kendo-grid-excel>
                    <kendo-grid-pdf fileName="rechargeHistory.pdf" [allPages]="true" paperSize="A4"
                      [landscape]="true"></kendo-grid-pdf>
                  </kendo-grid>
                </div>
                <!-- Pdf Edrt-->

                <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                  class="no-tooltip"></kendo-grid-checkbox-column>
                <!-- <kendo-grid-column field="date" title="Last Date time" [width]="150" format="{0:dd MMM yyyy HH:mm:ss}">
                </kendo-grid-column> -->
                <!-- <kendo-grid-column field="id" title="Transaction Id"></kendo-grid-column> -->
                <!-- <kendo-grid-column field="date" title="Date" format="{0:dd MMM yyyy HH:mm:ss}"> -->
                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.date}}
                  </ng-template> -->
                <kendo-grid-column field="date" title="Date" filter="date">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.date | date: 'dd MMM yyyy hh:mm a'}}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="rechargeAmount" title="Recharge Amount" filter="numeric">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.rechargeAmount | currency: 'INR':'symbol':'1.2-2' }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="currentBalance" title="Current Balance" filter="numeric">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.currentBalance | currency: 'INR':'symbol':'1.2-2' }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="paymentRefNumber" title="Payment Ref No"></kendo-grid-column>
                <kendo-grid-column field="mode" title="Mode of Payment"></kendo-grid-column>
                <!-- <kendo-grid-column field="transactionId" title="Transaction Id">
                </kendo-grid-column> -->

                <kendo-grid-column field="rechargeStatus" title="Recharge Status">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.rechargeStatus !== undefined" [ngClass]="{
                        'text-success': dataItem.rechargeStatus === 'Success',
                        'text-danger': dataItem.rechargeStatus === 'Failed' || dataItem.rechargeStatus ==='Cancelled',
                        'text-warning': dataItem.rechargeStatus === 'InProgress',
                        
                      }">
                      {{ dataItem.rechargeStatus }}
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="d-flex">
                      <!-- View Recharge Details Icon -->
                      <span kendoTooltip title="View Recharge Details" class="me-2"
                        (click)="viewRechargeDetails(dataItem)" *ngIf="dataItem.rechargeStatus === 'Success'">
                        <i class="fas fa-info-circle text-success" style="cursor: pointer; font-size: 20px;"></i>
                      </span>
                    </div>
                  </ng-template>
                </kendo-grid-command-column>







              </kendo-grid>
            </div>
          </section>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>