import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    icon: 'bx bxs-dashboard',
    link: '/dashboard',
    roles: ['SuperAdmin', 'SuperUser'],
    color: '#FADF89',
  },
  {
    id: 1,
    label: 'Dashboard',
    icon: 'bx bxs-dashboard',
    link: '/admindashboard',
    roles: ['Admin'],
    color: '#FADF89',
  },
  {
    id: 1,
    label: 'Dashboard',
    icon: 'bx bxs-dashboard',
    link: '/consumerdashboard',
    roles: ['Agent', 'Consumer'],
    color: '#FADF89',
  },
  {
    id: 27,
    label: 'Master & Suppliers',
    icon: 'bx bx-chip',
    link: '/masters',
    roles: ['SuperAdmin', 'SuperUser'],
    color: '#F9AF58',
  },
  {
    id: 24,
    label: 'Projects',
    icon: 'bx bx-building-house',
    link: '/projects',
    roles: ['SuperAdmin', 'SuperUser'],
    color: '#F37E53',
  },

  {
    id: 2,
    label: 'Project Details',
    icon: 'bx bx-building-house',
    link: '/projectDetails',
    roles: ['Admin'],
    color: '#E96360',
  },
  {
    id: 12,
    label: 'Users',
    icon: 'fas fa-users',
    link: '/users',
    roles: ['SuperAdmin', 'SuperUser'],
    color: '#DB6FA0',
  },
  {
    id: 1,
    label: 'Payments',
    icon: 'bx bx-credit-card-alt',
    link: '/payments',
    roles: ['SuperAdmin', 'SuperUser'],
    color: '#FADF89',
  },
  {
    id: 25,
    label: 'Invoice Ads',
    icon: 'bx bx-receipt',
    link: '/invoice',
    roles: ['SuperAdmin', 'SuperUser'],
    color: '#AD8AC0',
  },

  {
    id: 3,
    label: 'Utilities',
    icon: 'fas fa-boxes',
    link: '/utilities',
    roles: ['Admin'],
    color: '#6480BC',
  },
  /*  {
     id: 4,
     label: 'Unit Rate History',
     icon: 'bx-tone',
     link: '/unitratehistory',
     roles: ['Admin'],
   }, */

  {
    id: 4,
    label: 'Residential Unit',
    icon: 'fas fa-house-user',
    link: '/residentialUnit',
    roles: ['Admin'],
    color: '#63AEDB',
  },
  {
    id: 4,
    label: 'Unit Rate History',
    icon: 'fas fa-money-bill',
    link: '/unitratehistory',
    roles: ['Admin', 'Consumer'],
    billType: 'Prepaid',
    color: '#44C8F5',
  },

  {
    id: 6,
    label: 'Bills',
    icon: 'mdi mdi-file-document-multiple',
    link: '/postpaidBill',
    roles: ['Consumer_Postpaid'],
    color: '#60C5B4',
  },
  /*   {
      id: 7,
      label: 'Bill History',
      icon: 'bx-tone',
      link: '/billHistory',
      roles: [],
    }, */

  {
    id: 13,
    label: 'Consumers',
    icon: 'fas fa-users',
    link: '/consumers',
    roles: ['Admin'],
    color: '#78C68B',
  },
  {
    id: 14,
    label: 'Meters',
    icon: 'fas fa-tachometer-alt',
    link: '/meters',
    roles: ['Admin', 'SuperAdmin', 'SuperUser'],
    color: '#AFCC40',
  },
  {
    id: 15,
    label: 'Bills',
    icon: 'mdi mdi-file-document-multiple',
    link: '/bills',
    roles: ['Admin'],
    billType: 'Postpaid',
    color: '#FADF89',
  },
  {
    id: 15,
    label: 'Bills',
    icon: 'mdi mdi-file-document-multiple',
    link: '/postpaidBill',
    roles: ['Consumer'],
    billType: 'Postpaid',
    color: '#F9AF58',
  },
  {
    id: 15,
    label: 'Bill History',
    icon: 'mdi mdi-file-clock-outline',
    link: '/billHistory',
    roles: ['Consumer'],
    billType: 'Postpaid',
    color: '#F37E53',
  },
  {
    id: 15,
    label: 'Invoices',
    icon: 'bx bx-receipt',
    link: '/billInvoices',
    roles: ['Consumer', 'Admin',],
    billType: 'Prepaid',
    color: '#E96360',
  },
  {
    id: 16,
    label: 'Wallet',
    icon: 'dripicons-wallet',
    link: '/consumerWallet',
    isWalletEnabled: true,
    roles: ['Consumer'],
    color: '#DB6FA0',
  },
  {
    id: 16,
    label: 'Wallet History',
    icon: 'mdi mdi-wallet-plus',
    link: '/walletHistory',
    isWalletEnabled: true,
    roles: ['Consumer'],
    color: '#AD8AC0',
  },
  {
    id: 17,
    label: 'Recharge History',
    icon: 'mdi mdi-book-refresh-outline',
    link: '/rechargeHistory',
    isWalletEnabled: true,
    roles: ['Consumer'],
    color: '#6480BC',
  },
  {
    id: 18,
    label: 'Consumption History',
    icon: 'mdi mdi-file-clock-outline',
    link: '/consumptionHistory',
    roles: ['Consumer'],
    billType: 'Prepaid',
    color: '#63AEDB',
  }, {
    id: 19,
    label: 'Meter Readings',
    icon: 'fas fa-tachometer-alt',
    link: '/Meters',
    roles: ['Consumer'],
    billType: 'Postpaid',
    color: '#44C8F5',
  },
  {
    id: 19,
    label: 'Meter Details',
    icon: 'mdi mdi-speedometer-slow',
    link: '/meterDetails',
    roles: ['Consumer'],
    color: '#44C8F5',
  },
  {
    id: 20,
    label: 'Meter Controls',
    icon: 'mdi mdi-car-cruise-control',
    link: '/meterContolls',
    roles: ['Consumer'],
    color: '#60C5B4',
  },
  /* {
    id: 26,
    label: "FAQ's",
    icon: 'bx-error-circle',
    link: '/consumerFaq',
    roles: ['Consumer'],
  }, */
  {
    id: 21,
    label: 'Wallet',
    icon: 'dripicons-wallet',
    link: '/wallet',
    roles: ['Admin'],
    isWalletEnabled: true,
    color: '#78C68B',
  },
  {
    id: 22,
    label: 'Bill Settings',
    icon: 'mdi mdi-book-cog',
    link: '/billSettings',
    roles: ['Admin'],
    color: '#AFCC40',
  },
  {
    id: 23,
    label: 'Alerts',
    icon: 'bx-error',
    link: '/alerts',
    roles: ['F'],
    color: '#FADF89',
  },
  {
    id: 27,
    label: 'Reports',
    icon: 'bx bx-spreadsheet',
    link: '/reports',
    roles: ['SuperAdmin', 'SuperUser', 'Admin'],
    color: '#F9AF58',
  },

  {
    id: 26,
    label: "FAQ's",
    icon: 'fas fa-question-circle',
    link: '/faq',
    roles: ['SuperAdmin', 'SuperUser', 'Admin', 'Consumer'],
    color: '#F37E53',
  },

  {
    id: 28,
    label: 'Feedback History',
    icon: 'fas fa-handshake',
    link: '/feedbackHistory',
    roles: ['SuperAdmin', 'SuperUser', 'Admin'],
    color: '#E96360',
  },
  {
    id: 29,
    label: 'Power Bi',
    icon: 'fas fa-outdent',
    link: '/powerBi',
    roles: [ 'SuperUser'],
    color: '#FADF89',
  },
  /*  {
     id: 29,
     link: '/admindashboard',
     roles: ['SuperAdmin', 'SuperUser', 'Admin', 'Agent', 'Consumer'],
   },
   {
     id: 30,
     link: '/postpaidDashboard',
     roles: ['SuperAdmin', 'SuperUser', 'Admin', 'Agent', 'Consumer'],
   },
   {
     id: 31,
     link: '/consumerdashboard',
     roles: ['Agent', 'Consumer'],
   }, */
  /* {
    id: 32,
    link: '/prepaidConsumerDashboard',
    roles: ['SuperAdmin', 'SuperUser', 'Admin', 'Agent', 'Consumer'],
  }, */
];
