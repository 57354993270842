<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    @if(cdkStepper.selectedIndex === 0){ <button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                    @if(cdkStepper.selectedIndex === 1){ <button class="btn btn-primary d-flex align-items-center"
                        (click)="exportAllMeter()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>}
                </span>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
       
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div id="basic-example">
                        <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper">

                            <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">1</span>
                                    <span>Meter</span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 0){
                                <section>
                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>
                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">
                                        <kendo-grid [data]="MeterControlsgridData" [reorderable]="true"
                                            filterable="menu" [columnMenu]="{ filter: true }" [resizable]="true"
                                            [pageSize]="pageSize" [pageable]="{
                                     buttonCount: buttonCount,
                                    info: info,
                                     type: type,
                                    pageSizes: pageSizes,
                                    previousNext: previousNext
                                  }" [selectable]="selectableSettings" [filter]="meterControlsState.filter"
                                            [skip]="skip" [sort]="meterControlsState.sort"
                                            [columnMenu]="{ filter: true }" scrollable="scrollable"
                                            (filterChange)="onMetertFilterChange($event)"
                                            (sortChange)="onSortChange($event)" [sortable]="true" [resizable]="true"
                                            (selectionChange)="onSelect($event)" (pageChange)="meterPageChange($event)"
                                            (dataStateChange)="consumptionStateChange($event)">
                                            <ng-template kendoGridToolbarTemplate>
                                                <!-- <kendo-textbox [style.width.px]="250"
                                                    placeholder="Search in all columns..."
                                                    (valueChange)="onFilter($event)"></kendo-textbox> -->

                                                <div class="search_icon">
                                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                                        (valueChange)="meterValueChange($event)" (keydown.enter)="onSearchMeterControll()"
                                                         [style.height.px]="35"
                                                        [(ngModel)]="searchedValue"
                                                        placeholder="Search in all columns...">
                                                    </kendo-textbox>

                                                    <button class="btn btn-primary btn-md border-left-none"
                                                        (click)="onFilter($event)">
                                                        <i class="fa fa-search"></i>
                                                    </button>
                                                </div>
                                                <kendo-grid-spacer></kendo-grid-spacer>

                                                <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                    (click)="onExport('Excel')">
                                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                </button>

                                                <!-- <button class="btn btn-light" (click)="onExport('Pdf')">
                                                <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->

                                            </ng-template>

                                            <!-- Pdf   start-->

                                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                                <kendo-grid
                                                    [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:MeterControlsgridData.data"
                                                    #pdfGrid>

                                                    <kendo-grid-column field="meterSrNo"
                                                        title="Device Sr.No"></kendo-grid-column>
                                                    <kendo-grid-column field="macAddress"
                                                        title="Mac Address"></kendo-grid-column>
                                                    <kendo-grid-column field="utility"
                                                        title="Utility"></kendo-grid-column>
                                                    <!-- <kendo-grid-column field="meterStatus"
                                                        title="Valve Relay status"></kendo-grid-column> -->
                                                    <kendo-grid-column field="valveOrRelayStatus" title="Meter On/Off"
                                                        [width]="250"></kendo-grid-column>

                                                    <kendo-grid-excel fileName="Meters.xlsx"></kendo-grid-excel>
                                                    <kendo-grid-pdf fileName="Meters.pdf" [allPages]="true"
                                                        paperSize="A4" [landscape]="true"></kendo-grid-pdf>
                                                </kendo-grid>
                                            </div>
                                            <!-- Pdf Edrt-->


                                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                            <kendo-grid-column field="meterSrNo"
                                                title="Device S.No"></kendo-grid-column>
                                            <kendo-grid-column field="macAddress"
                                                title="Mac Address"></kendo-grid-column>
                                            <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                                            <!--  <kendo-grid-column title=" Valve Relay status" field="meterStatus">
                                            </kendo-grid-column> -->


                                            <kendo-grid-column title="Meter On/Off" [width]="150">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="d-flex flex-column align-items-center justify-content-center"
                                                        [hidden]="dataItem.utility === 'Water'">

                                                        <!-- Display Valve/Relay status with smaller font size -->
                                                        <span class="status-text mb-1"
                                                            [ngClass]="{'text-success': dataItem.valveOrRelayStatus, 'text-danger': !dataItem.valveOrRelayStatus}"
                                                            style="font-size: 0.875rem;">
                                                            {{ dataItem.valveOrRelayStatus == true? 'ON' : 'OFF' }}
                                                        </span>

                                                        <!-- Display Meter On/Off action link with styled button-like appearance -->
                                                        <div *ngIf="isMetermenu">
                                                            <div class="mt-1">
                                                                <a (click)="toggleMeterStatus(dataItem.valveOrRelayStatus, dataItem)"
                                                                    *ngIf="(dataItem.onRequest === 'No' && dataItem.offRequest === 'No') ||
                                                                 (dataItem.onRequest === '' && dataItem.offRequest === '')"
                                                                    class="meter-link btn btn-sm"
                                                                    [ngClass]="dataItem.valveOrRelayStatus ? 'btn-outline-danger' : 'btn-outline-success'"
                                                                    style="font-size: 0.8rem; text-transform: uppercase; padding: 0.2rem 0.6rem;">
                                                                    {{ dataItem.valveOrRelayStatus
                                                                    ? 'Turn Off' : 'Turn On' }}
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>




                                            <!-- <kendo-grid-command-column title="Meter On/Off" [columnMenu]="false"
                                                [width]="120">

                                                  <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="d-flex align-items-center justify-content-center"
                                                        [hidden]="dataItem.utility === 'Water'">
                                                        <ui-switch color="#f46a6a" [checked]="dataItem.status"
                                                            [disabled]="dataItem.isMeterOnOff" uncheckedLabel="No"
                                                            checkedLabel="Yes" size="small" class="me-1"
                                                            (change)="ActivationChange($event, dataItem)">
                                                        </ui-switch>
                                                    </div>

                                                </ng-template> 
                                                
                                            </kendo-grid-command-column>-->

                                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="70">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="d-flex">
                                                        <!-- Check Current Status Icon -->
                                                        <span [hidden]="dataItem.utility != 'Electricity'" kendoTooltip
                                                            title="Check Current Status"
                                                            (click)="checkRelayStatus(dataItem)">
                                                            <img src="../../../assets/images/checkmeter.svg" alt=""
                                                                height="22">
                                                        </span>
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-command-column>





                                        </kendo-grid>
                                    </div>
                                    <!-- <ul class="list-inline wizard d-flex justify-content-end mt-4">
                                        <li class="previous list-inline-item" aria-disabled="true"><button
                                                class="btn btn-outline-primary" cdkStepperPrevious>Previous</button>
                                        </li>
                                        <li class="next list-inline-item" aria-disabled="false"><button
                                                class="btn btn-primary" cdkStepperNext>Next</button></li>
                                    </ul> -->
                                </section>
                                }
                            </cdk-step>

                            <cdk-step [optional]="true">
                                <ng-template cdkStepLabel>
                                    <span class="number">2</span>
                                    <span>On/Off History </span>
                                </ng-template>
                                @if(cdkStepper.selectedIndex === 1){
                                <section>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="mb-3">
                                                            <label class="form-label">From Date -To Date </label>
                                                            <div class="position-relative">
                                                                <input type="text" class="form-control date"
                                                                    [value]="meterOnOfffromDate ? (formatDateRange(meterOnOfffromDate)) : ''"
                                                                    placeholder="Select Date" name="daterange"
                                                                    autocomplete="off" bsDaterangepicker
                                                                    [bsConfig]="datePickerConfig"
                                                                    [(bsValue)]="meterOnOfffromDate" />
                                                                <span class="bx bx-calendar inputIcon"
                                                                    (click)="meterOnOffopenCalendar()"></span>
                                                                <!-- Clear icon -->
                                                                <span class="bx bx-x inputIcon clear-icon"
                                                                    *ngIf="meterOnOfffromDate.length>0"
                                                                    (click)="clearDate()"></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 d-flex align-items-center">
                                                        <button class="btn btn-primary btn-md mt-2"
                                                            (click)="metreOnoFffilterBasedOnDate(1)">Filter</button>
                                                        <button class="btn btn-secondary btn-md mt-2 ms-2"
                                                            (click)="metreOnoFffilterBasedOnDate(0)">Clear</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </section>
                                <section class="mt-3">
                                    <ng-template #template let-anchor>
                                        <span>{{ anchor.nativeElement.innerText }}</span>
                                    </ng-template>
                                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                                        (mouseover)="showTooltip($event)">

                                        <kendo-grid [data]="MeterOnOffHostotrydData" [reorderable]="true"
                                            filterable="menu" [columnMenu]="{ filter: true }" [resizable]="true"
                                            [pageSize]="meteronOffpagesize" [pageable]="{
                                     buttonCount: buttonCount,
                                    info: info,
                                     type: type,
                                    pageSizes: meterOnOffHistoryPageSize,
                                    previousNext: previousNext
                                  }" [selectable]="selectableSettings" [filter]="meterOnOffControlsState.filter"
                                            [skip]="skip" [sort]="meterOnOffControlsState.sort"
                                            [columnMenu]="{ filter: true }"
                                            (filterChange)="onOffMetertFilterChange($event)"
                                            (sortChange)="onOffSortChange($event)" [sortable]="true" [resizable]="true"
                                            (selectionChange)="onOffSelect($event)"
                                            (pageChange)="meterOnOffPageChange($event)"
                                            (dataStateChange)="OnoffStateChange($event)">

                                            <ng-template kendoGridToolbarTemplate>
                                                <!-- <kendo-textbox [style.width.px]="250"
                                                    placeholder="Search in all columns..."
                                                    (valueChange)="onFilterOnOff($event)"></kendo-textbox> -->

                                                <div class="search_icon">
                                                    <kendo-textbox [style.width.px]="250"
                                                        [(ngModel)]="meteronOffsearchedValue" [clearButton]="true"
                                                        (valueChange)="MeterOnOffValueChange($event)" 
                                                        (keydown.enter)="onSearchonoffHistory()"
                                                        [style.height.px]="35" [style.height.px]="35"
                                                        [style.height.px]="35" placeholder="Search in all columns...">
                                                    </kendo-textbox>

                                                    <button class="btn btn-primary btn-md border-left-none"
                                                        (click)="onFilterOnOff($event)">
                                                        <i class="fa fa-search"></i>
                                                    </button>
                                                </div>
                                                <kendo-grid-spacer></kendo-grid-spacer>
                                                <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                                    (click)="onMeterExport('Excel')">
                                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                                </button>

                                                <!-- <button class="btn btn-light" (click)="onMeterExport('Pdf')">
                                                <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->



                                            </ng-template>


                                            <!-- Pdf   start-->

                                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                                <kendo-grid
                                                    [kendoGridBinding]="selectedRowdataOnOff.length>0 ? selectedRowdataOnOff:MeterOnOffHostotrydData.data"
                                                    #pdfGrid>
                                                    <kendo-grid-column field="meterSrNo" title="Device Sr No"
                                                        [width]="150"></kendo-grid-column>
                                                    <kendo-grid-column field="macAddress"
                                                        title="Mac Address"></kendo-grid-column>
                                                    <kendo-grid-column field="utilityType"
                                                        title="Utility"></kendo-grid-column>
                                                    <kendo-grid-column field="onOffStatus"
                                                        title="Meter On/Off Status"></kendo-grid-column>
                                                    <kendo-grid-column field="onOffDate" title="Date" [width]="130">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            {{dataItem.onOffDate!==null?
                                                            ( dataItem.onOffDate | date: 'dd MMM yyyy hh:mm a') :'-'}}

                                                        </ng-template>
                                                    </kendo-grid-column>
                                                    <!-- <kendo-grid-column [field]="getDate(dataItem)" title="Date"
                                                    [width]="250"></kendo-grid-column> -->

                                                    <kendo-grid-excel fileName="MeterOnOff.xlsx"></kendo-grid-excel>

                                                    <!--  <kendo-grid-pdf fileName="MeterOnOff.pdf" [allPages]="true"
                                                        paperSize="A4" [landscape]="true"></kendo-grid-pdf> -->
                                                </kendo-grid>
                                            </div>
                                            <!-- Pdf Edrt-->


                                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true"
                                                class="no-tooltip" [width]="40"></kendo-grid-checkbox-column>
                                            <kendo-grid-column field="meterSrNo" title="Device Sr No"
                                                [width]="150"></kendo-grid-column>
                                            <kendo-grid-column field="macAddress" title="Mac Address"
                                                [width]="150"></kendo-grid-column>
                                            <kendo-grid-column field="utilityType" title="Utility"
                                                [width]="150"></kendo-grid-column>
                                            <kendo-grid-column field="onOffStatus" title="Meter On/Off Status"
                                                [width]="130"></kendo-grid-column>
                                            <kendo-grid-column field="onOffDate" title="Date" [width]="130"
                                                filter="date">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    {{dataItem.onOffDate!==null?
                                                    ( dataItem.onOffDate | date: 'dd MMM yyyy hh:mm a') :'-'}}


                                                </ng-template>
                                            </kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                </section>}
                            </cdk-step>

                        </ng-stepper>
                    </div>

                </div>
                <!-- end card body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>

</div>