import { createFeatureSelector, createSelector } from '@ngrx/store';
import { billSettingState } from './billSettings.reducer';

export const selectDataState =
  createFeatureSelector<billSettingState>('billSetting');

export const getBillSettingResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.getBillSettingResponse
);

export const getBillinvloiceResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.getBillinvloiceResponse
);

export const getAllBillinvloiceResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.getAllBillinvloiceResponse
);

export const updateOptionalBillChargeResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.updateOptionalBillChargeResponse
);

export const getOptionalResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.getOptionalResponse
);

export const createBillChargeResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.createBillChargeResponse
);

export const deleteBillChargeResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.deleteBillChargeResponse
);

export const deleteInvoiceResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.deleteInvoiceResponse
);

export const getAllBillSettingResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.getAllBillSettingResponse
);

export const updateInvoiceResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.updateInvoiceResponse
);

export const updateBillChargeResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.updateBillChargeResponse
);


export const createInvoiceResponse = createSelector(
  selectDataState,
  (state: billSettingState) => state.createInvoiceResponse
);




//
//
