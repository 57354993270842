<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>

                </span>

            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-right">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>

                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid [data]="metergridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                            [selectable]="selectableSettings" [pageable]="{
                                    buttonCount: buttonCount,
                                 info: info,
                                  type: type,
                                 pageSizes: pageSizes,
                                  previousNext: previousNext
                              }" [reorderable]="true" filterable="menu" [filter]="meterState.filter"
                            [sort]="meterState.sort" [columnMenu]="{ filter: true }"
                            (filterChange)="onMeterFilterChange($event)" (sortChange)="onSortChange($event)"
                            [resizable]="true" scrollable="scrollable" (pageChange)="meterConfigPageChange($event)"
                            (selectionChange)="selectionChanged($event)" (dataStateChange)="dataStateChange($event)">

                            <ng-template kendoGridToolbarTemplate>
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" (keyup.enter)="onSearchMeterAds()"
                                        [style.height.px]="35" [(ngModel)]="searchedValue"
                                        placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none"
                                        (click)="onMeterConfigFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>

                                <kendo-grid-spacer></kendo-grid-spacer>
                                <!-- <div class="sm-device d-flex justify-content-between">
                                    <button class="btn btn-outline-primary btn-sm" (click)="clearFilter()">
                                        <span class="fas fa-file-excel"> </span> &nbsp; Clear Filter
                                    </button>
                                </div> -->
                                <div class="sm-device d-flex justify-content-between">
                                    <button class="btn btn-outline-primary btn-sm" (click)="onMeterPdfExport('Excel')">
                                        <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                    </button>
                                </div>
                            </ng-template>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column>
                            <kendo-grid-column *ngIf="projectId==null" field="projectName" title="Project Name"
                                [width]="100">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="utility" title="Utility" [width]="100">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column field="deviceSrNo" title="Device Serial No" [width]="100">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Mac Address" [hidden]="true"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="meterSatus" title="Status" [hidden]="ishide"
                                [width]="80"></kendo-grid-column>

                            <!--  <kendo-grid-column title="Active/Inactive" field="status" [width]="100" filter="boolean">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <ui-switch [ngClass]="{
                                            'switch-checked':dataItem.meterSatus == Active?true:false,
                                              'switch-unchecked': !dataItem.meterSatus == Active?false: }"
                                            [checked]="dataItem.meterSatus" uncheckedLabel="" checkedLabel=""
                                            size="small" class="me-1" [disabled="true" ]></ui-switch>
                                    </div>
                                </ng-template>
                            </kendo-grid-column> -->


                            <kendo-grid-column field="currentReading" title="Reading" [width]="90" filter="numeric">

                                <!--  <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.utility=='Water'?dataItem.currentReading+' L':
                                    dataItem.utility=='Electricity'?
                                    '-':
                                    dataItem.utility=='Gas'?dataItem.currentReading+' m³':''
                                    }}
                                </ng-template> -->

                            </kendo-grid-column>
                            <kendo-grid-column filter="numeric" field="currentReadingEB" [width]="90"
                                title="EB Reading">
                                <!--  <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.utility=='Electricity' ? (dataItem.currentReadingEB
                                    !=undefined?dataItem.currentReadingEB+' kWh':''): '-' }}
                                </ng-template> -->


                            </kendo-grid-column>
                            <kendo-grid-column filter="numeric" field="currentReadingDG" [width]="90"
                                title="DG Reading ">
                                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.utility=='Electricity' ? (dataItem.currentReadingDG
                                    !=undefined?dataItem.currentReadingDG+' kWh':''): '-' }}
                                </ng-template></kendo-grid-column> -->

                            </kendo-grid-column>
                            <kendo-grid-column field="occupant" [hidden]="true" title="Occupant"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="currentDate" [width]="100" title="Last Communicated time"
                                filter="date" [hidden]="true" format="{0:dd MMM yyyy}">
                            </kendo-grid-column>
                            <!-- <kendo-grid-column field="currentDate" title="Current Date" filter="date">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.currentDate | date: 'dd MMM yyyy'}}
                                </ng-template>
                            </kendo-grid-column> -->

                            <kendo-grid-column field="residentialUnit" title="Residential Unit"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="onRequest" [hidden]="true" title="On request sent"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="offRequest" [hidden]="true" title="Off request sent"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="batterLevel" [hidden]="true" title="Battery Level"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="batteryVoltage" filter="numeric" [hidden]="true"
                                title="Battery Voltage" [width]="100"></kendo-grid-column>

                            <kendo-grid-column field="meterCommunicationStatus" title="Communication Status"
                                [width]="90" filter="boolean">
                                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <ui-switch color="#f46a6a" [checked]="dataItem.meterCommunicationStatus"
                                            uncheckedLabel="Off" checkedLabel="On" size="small" class="me-1"
                                            [disabled]="true">
                                        </ui-switch>
                                    </div>
                                </ng-template> -->
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center">
                                        {{dataItem.communicationStatus}}
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <!-- <kendo-grid-column title="Valve/Relay status" [width]="100">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center"
                                        [hidden]="dataItem.utility === 'Water'">
                                        <ui-switch color="#f46a6a" [checked]="dataItem?.valveOrRelayStatus"
                                            uncheckedLabel="Off" checkedLabel="On" size="small" class="me-1"
                                            [disabled]="dataItem.onRequest === 'Yes' || dataItem.offRequest === 'Yes'"
                                            (change)="toggleMeterStatus($event, dataItem)">
                                        </ui-switch>
                                        <h1>{{dataItem?.valveOrRelayStatus?'On':'Off'}}</h1>
                                        <a  (change)="toggleMeterStatus($event, dataItem)" *ngIf="dataItem.onRequest === 'No' || dataItem.offRequest === 'No'">{{dataItem?.valveOrRelayStatus?'Meter Off':'Meter On'}} </a>

                                        <ui-switch color="#f46a6a" [checked]="checkBtnStatus(dataItem)"
                                            uncheckedLabel="Off" checkedLabel="On" size="small" class="me-1"
                                            [disabled]="true" (change)="toggleMeterStatus($event, dataItem)">
                                        </ui-switch>
                                    </div>
                                </ng-template>
                            </kendo-grid-column> -->

                            <kendo-grid-column title="Valve/Relay Status" field="valveOrRelayStatus" [width]="100"
                                filter="boolean">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex flex-column align-items-center justify-content-center"
                                        [hidden]="dataItem.utility === 'Water'">

                                        <!-- Display Valve/Relay status with smaller font size -->

                                        <span class="status-text mb-1"
                                            [ngClass]="{'text-success': dataItem.valveOrRelayStatus=='On', 'text-danger': dataItem.valveOrRelayStatus=='Off'}"
                                            style="font-size: 0.875rem;">
                                            {{dataItem.utility=='Water'?'': dataItem.valveOrRelayStatus=='On' ? 'ON' :
                                            'OFF'
                                            }}
                                        </span>

                                        <!-- Display Meter On/Off action link with styled button-like appearance -->
                                        <!-- <div class="mt-1">
                                            <button
                                                (click)="toggleMeterStatus(dataItem.valveOrRelayStatus, dataItem)"
                                                *ngIf="(dataItem.onRequest === 'No' && dataItem.offRequest === 'No') || 
                                                 (dataItem.onRequest === '' && dataItem.offRequest === '')"
                                                class="meter-link btn btn-sm"
                                                [ngClass]="dataItem.valveOrRelayStatus=='On' ? 'btn-outline-danger' : 'btn-outline-success'"
                                                style="font-size: 0.8rem; text-transform: uppercase; padding: 0.2rem 0.6rem;">
                                                {{ dataItem.valveOrRelayStatus=='On' ? 'Turn Off' : 'Turn On' }}
                                            </button>

                                        </div> -->
                                        <!-- Modified by ramesh for meter trun off need to send payload sttaus: off, turn on click ->status: on  -->
                                        <div class="mt-1">
                                            <button
                                                (click)="toggleMeterStatus(dataItem.valveOrRelayStatus=='On' ? 'Off' : 'On', dataItem)"
                                                *ngIf="(dataItem.onRequest === 'No' && dataItem.offRequest === 'No') || 
                                                 (dataItem.onRequest === '' && dataItem.offRequest === '')"
                                                class="meter-link btn btn-sm"
                                                [ngClass]="dataItem.valveOrRelayStatus=='On' ? 'btn-outline-danger' : 'btn-outline-success'"
                                                style="font-size: 0.8rem; text-transform: uppercase; padding: 0.2rem 0.6rem;">
                                                {{ dataItem.valveOrRelayStatus=='On' ? 'Turn Off' : 'Turn On' }}
                                            </button>

                                        </div>

                                    </div>
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="70">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <!-- Meter Info Icon -->
                                        <span *ngIf="userRole == listOfRoles.ADMIN" kendoTooltip title="Meter Info"
                                            class="me-3" (click)="navigateToMeterView(dataItem)"
                                            style="cursor: pointer;">
                                            <i class="fas fa-info-circle text-success" style="font-size: 20px;"></i>
                                        </span>

                                        <!-- Check Current Status Icon (only for Electricity) -->
                                        <span kendoTooltip title="Check Current Status"
                                            (click)="checkRelayStatus(dataItem)"
                                            *ngIf="dataItem.utility == 'Electricity'" style="cursor: pointer;">
                                            <!-- <i class="fas fa-tachometer-alt text-primary"></i> -->
                                            <img src="../../../assets/images/checkmeter.svg" alt="" height="22">
                                        </span>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>
                        </kendo-grid>
                    </div>

                    <!--master download buttons start-->

                    <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                            [kendoGridBinding]="mySelectionMeter.length>0 ? mySelectionMeter:allMeterconfigList.length>0?allMeterconfigList:metergridData.data"
                            #pdfGrid>
                            <!-- <kendo-grid-column *ngIf="projectId==null" field="projectName" title="Project Name"
                                [width]="100"></kendo-grid-column> -->
                            <kendo-grid-column *ngIf="projectId==null" field="projectName" title="Project Name"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                            <kendo-grid-column field="deviceSrNo" title="Device Serial No"
                                [width]="100"></kendo-grid-column>
                            <kendo-grid-column field="macAddress" title="Mac Address"></kendo-grid-column>
                            <kendo-grid-column field="meterSatus" title="Status"></kendo-grid-column>

                            <kendo-grid-column field="currentReading" filter="numeric" title="Reading">
                            </kendo-grid-column>
                            <kendo-grid-column filter="numeric" field="currentReadingDG" title="DG Reading ">
                            </kendo-grid-column>
                            <kendo-grid-column filter="numeric" field="currentReadingEB" title="EB Reading">

                            </kendo-grid-column>
                            <kendo-grid-column field="occupant" title="Occupant"></kendo-grid-column>
                            <!-- <kendo-grid-column field="currentDate" title="Last Communicated time"></kendo-grid-column> -->
                            <kendo-grid-column field="currentDate" title="Last Communicated Time">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ isValidDate(dataItem.currentDate) ? (dataItem.currentDate | date: 'dd MMM
                                    yyyy')
                                    : '-' }}
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column field="residentialUnit" title="Residential Unit"></kendo-grid-column>
                            <kendo-grid-column field="communicationStatus" title="Communication Status">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center">
                                        {{dataItem.communicationStatus}}
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="onRequest" title="On request sent"></kendo-grid-column>
                            <kendo-grid-column field="offRequest" title="Off request sent"></kendo-grid-column>
                            <kendo-grid-column field="batterLevel" title="Battery Level"></kendo-grid-column>
                            <kendo-grid-column field="batteryVoltage" filter="numeric"
                                title="Battery Voltage"></kendo-grid-column>
                            <kendo-grid-column title="Valve/Relay Status" field="valveOrRelayStatus">
                            </kendo-grid-column>
                            <kendo-grid-pdf fileName="Metesreport.pdf" [allPages]="true" paperSize="A4"
                                [landscape]="true"></kendo-grid-pdf>
                            <kendo-grid-excel fileName="Metesreport.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>