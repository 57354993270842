import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';
import { Umd } from '../../shared/modals/common';

@Injectable({ providedIn: 'root' })
export class UmdService {
  constructor(private http: HttpClient, private router: Router) {}

  getUmds(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    projectId?: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;

    url =
      `${AppConfigData.getUmdUrl + projectId}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getUmds failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getelectricitystatus(
    pageIndex: number,
    pageSize: number | undefined,
    searchTerm: string,
    projectId?: string,
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;

    url =
      `${
        AppConfigData.getelectricitystatus + projectId
      }?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getUmds failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  // createutilities(UmdData: any): Observable<any> {
  //   const formData = new FormData();
  //   formData.append('ProjectId', UmdData.projectId);
  //   formData.append('Gas.UtilityMasterId', UmdData.gas.utilityMasterId);
  //   formData.append('Gas.GasValue', UmdData.gas.gasValue);
  //   formData.append('Gas.UnitLPGValue', UmdData.gas.UnitLPGValue);
  //   formData.append('Gas.UnitPrice', UmdData.gas.unitPrice);
  //   formData.append('Gas.File', UmdData.gas.File);
  //   formData.append('Water', UmdData.water);
  //   formData.append('Electricity', UmdData.electricity);
  //   formData.append('RemovedUtilityIds', UmdData.removedUtilityIds);
  //   formData.append('WaterFile', UmdData.WaterFile);
  //   formData.append('ElectricityFile', UmdData.ElectricityFile);
  //   return this.http.post<any>(AppConfigData.createUtilityUrl, formData).pipe(
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       console.error('CreateUtility failed:', error);
  //       return throwError(error); // Rethrow the error to propagate it further
  //     })
  //   );
  // }

  createutilities(UmdData: any): Observable<any> {
    // Initialize formData object
    const formData = new FormData();
    formData.append('ProjectId', UmdData.projectId || '');
    // formData.append(
    //   'Gas.UtilityMasterId',
    //   UmdData.gas?.utilityMasterId || '' // Use empty string if undefined or null
    // );
    // formData.append(
    //   'Gas.GasValue',
    //   UmdData.gas?.gasValue != null ? UmdData.gas.gasValue : '' // Preserve 0 and other values, use empty string if null or undefined
    // );
    // formData.append(
    //   'Gas.UnitLPGValue',
    //   UmdData.gas?.UnitLPGValue || '' // Use empty string if undefined or null
    // );
    // formData.append(
    //   'Gas.UnitPrice',
    //   UmdData.gas?.unitPrice || '' // Use empty string if undefined or null
    // );
    // if (UmdData?.gas&&UmdData?.gas?.File) {
    //   formData.append('Gas.File', UmdData.gas.File);
    // }

    if (UmdData.gas?.utilityMasterId) {
      formData.append('Gas.UtilityMasterId', UmdData.gas.utilityMasterId);
    }
    
    if (UmdData.gas?.gasValue) {  // Checks for both null and undefined, preserves 0 and other falsy values
      formData.append('Gas.GasValue', UmdData.gas.gasValue.toString());
    }
    
    if (UmdData.gas?.UnitLPGValue) {
      formData.append('Gas.UnitLPGValue', UmdData.gas.UnitLPGValue.toString());
    }
    
    if (UmdData.gas?.unitPrice) {
      formData.append('Gas.UnitPrice', UmdData.gas.unitPrice.toString());
    }
    
    if (UmdData.gas?.File) {
      formData.append('Gas.File', UmdData.gas.File);
    }
    
    if( UmdData.gas?.gasValue){
      formData.append('IsGasFileDeleted', UmdData.IsGasFileDeleted);
    }

    // Add Water array elements
    // if (UmdData.water && UmdData.water.length > 0) {
    //   UmdData.water.forEach((waterItem: any, index: number) => {
    //     formData.append(`Water[${index}]`, JSON.stringify(waterItem)); // Convert each object to a string
    //   });
    // }

    // Initialize water as an empty array if not defined
    const waterData = UmdData.water || [];

    // Iterate through water data if it exists
    waterData.forEach((waterItem: any, index: number) => {
      formData.append(
        `Water[${index}].utilityMasterId`,
        waterItem.utilityMasterId != null ? waterItem.utilityMasterId : '' // Preserve 0 and other valid values, use empty string if null or undefined
      );
      formData.append(
        `Water[${index}].id`,
        waterItem?.id.length != 0
          ? waterItem?.id
          : '00000000-0000-0000-0000-000000000000' // Preserve 0 and other valid values, use empty string if null or undefined
      );
      formData.append(
        `Water[${index}].slabName`,
        waterItem.slabName != null ? waterItem.slabName : '' // Preserve empty string and other valid values, use empty string if null or undefined
      );
      formData.append(
        `Water[${index}].MinValue`,
        waterItem.MinValue != null ? waterItem.MinValue : '' // Preserve 0 and other valid values, use empty string if null or undefined
      );
      formData.append(
        `Water[${index}].MaxValue`,
        waterItem.MaxValue != null ? waterItem.MaxValue : '' // Preserve 0 and other valid values, use empty string if null or undefined
      );
      formData.append(
        `Water[${index}].unitPrice`,
        waterItem.unitPrice != null ? waterItem.unitPrice : '' // Preserve 0 and other valid values, use empty string if null or undefined
      );
    });

    // Initialize electricity as an empty array if not defined
    const electricityData = UmdData.electricity || [];

    // Iterate through electricity data if it exists
    electricityData.forEach((electricityItem: any, index: number) => {
      formData.append(
        `Electricity[${index}].utilityMasterId`,
        electricityItem.utilityMasterId != null
          ? electricityItem.utilityMasterId
          : '' // Preserve 0 and other valid values
      );
      formData.append(
        `Electricity[${index}].id`,
        electricityItem.id != null
          ? electricityItem.id
          : '00000000-0000-0000-0000-000000000000' // Preserve 0 and other valid values
      );
      formData.append(
        `Electricity[${index}].slabName`,
        electricityItem.slabName != null ? electricityItem.slabName : '' // Preserve empty string and other valid values
      );
      formData.append(
        `Electricity[${index}].minKwh`,
        electricityItem.minKwh != null ? electricityItem.minKwh : '' // Preserve 0 and other valid values
      );
      formData.append(
        `Electricity[${index}].maxKwh`,
        electricityItem.maxKwh != null ? electricityItem.maxKwh : '' // Preserve 0 and other valid values
      );
      formData.append(
        `Electricity[${index}].unitPrice`,
        electricityItem.unitPrice != null ? electricityItem.unitPrice : '' // Preserve 0 and other valid values
      );
    });

    // Add RemovedUtilityIds array elements
    if (UmdData.removedUtilityIds && UmdData.removedUtilityIds.length > 0) {
      UmdData.removedUtilityIds.forEach((id: any, index: number) => {
        formData.append(`RemovedUtilityIds[${index}]`, id);
      });
    }

    // Add Water file if available
    if (UmdData?.WaterFile) {
      formData.append('WaterFile', UmdData.WaterFile);
    }

    if(waterData.length>0){
      formData.append('IsWaterFileDeleted', UmdData.IsWaterFileDeleted);
    }

    // Add Electricity file if available
    if (electricityData.length>0&&UmdData.ElectricityFile) {
      formData.append('ElectricityFile', UmdData.ElectricityFile);
    }

    if(electricityData.length>0){
      formData.append('IsElectricityFileDeleted', UmdData.IsElectricityFileDeleted );
    }

    // Make the API call
    return this.http.post<any>(AppConfigData.createUtilityUrl, formData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('CreateUtility failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getutilities(id: Umd): Observable<any> {
    return this.http.get<any>(AppConfigData.getUtilityUrl + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('CreateUtility failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getutilitiesRateHistory(unitRatePayload: any): Observable<any> {
    const {
      utilityMasterId,
      projectId,
      pageIndex,
      pageSize,
      searchTerm,
      filter,
      orderBy,
    } = unitRatePayload;
    const url =
      `${AppConfigData.getutilitiesRateHistoryUrl + projectId}` +
      // `projectId=${projectId}` +
      `?utilityMasterId=${utilityMasterId}` +
      `${pageIndex ? `&pageIndex=${pageIndex}` : ''}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('CreateUtility failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createUmds(UmdData: any): Observable<any> {
    // Clone the UmdData object and update the supplierId if necessary
    const newUmdData = { ...UmdData, supplierId: UmdData.supplierId ==""? '00000000-0000-0000-0000-000000000000':UmdData.supplierId };

    return this.http.post<any>(AppConfigData.createUmdUrl, newUmdData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateUmd(UmdData: any, id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.updateUmdUrl + id, UmdData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  deleteUmd(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteUmdUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
