<div class="container-fluid">

  <!--  <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb> -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>

        </span>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>

          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [data]="billInvoicegridData" [reorderable]="true" scrollable="scrollable" [resizable]="true"
              [pageable]="{
                                      buttonCount: buttonCount,
                                      info: info,
                                      type: type,
                                      pageSizes: pageSizes,
                                      previousNext: billpreviousNext
                                    }" [pageSize]="pageSize" [sortable]="true" [selectable]="selectableSettings"
              filterable="menu" [columnMenu]="{ filter: true }" [filter]="billsInvoice.filter"
              [skip]="billsInvoice.skip" [sort]="billsInvoice.sort" (filterChange)="onInvoiceFilterChange($event)"
              (sortChange)="onSortChange($event)" [resizable]="true" scrollable="scrollable"
              (pageChange)="invoicePageChange($event)" (dataStateChange)="invoicetateChange($event)"
              (selectionChange)="invoiceSelectforBillSetting($event)">

              <ng-template kendoGridToolbarTemplate>
                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                  [(ngModel)]="invoicesearchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->
                <div class="search_icon">
                  <kendo-textbox [style.width.px]="250" [style.height.px]="35" [clearButton]="true"
                    (valueChange)="onValueChange($event)"   (keyup.enter)="onSearchInvoicesAds()" [style.height.px]="35" [(ngModel)]="invoicesearchedValue"
                    placeholder="Search in all columns...">
                  </kendo-textbox>

                  <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button class="btn  btn-outline-primary btn-sm" (click)="opemInvoiceModel(addAdvertismentModal)">Add New
                  Advertisement
                </button>
                <button class="btn btn-outline-primary btn-sm d-flex align-items-center" (click)="onExport('Excel')">
                  <span class="fas fa-file-excel"></span> &nbsp; Export to Excel
                </button>

              </ng-template>

              <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                class="no-tooltip"></kendo-grid-checkbox-column>
              <kendo-grid-column title="Image" [width]="100" [class]="{ 'text-center': true }" class="no-tooltip">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <img class="rounded" [src]="dataItem.fileUrl" [width]="30" />
                </ng-template>
              </kendo-grid-column>
              <!-- <kendo-grid-column field="from" title="From and To Date" filter="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.from | date: 'dd MMM yyyy'}}-{{dataItem.to | date: 'dd MMM yyyy'}}
                </ng-template>
              </kendo-grid-column> -->
              <kendo-grid-column field="from" title="From Date" filter="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.from | date: 'dd MMM yyyy'}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="to" title="To Date" filter="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.to | date: 'dd MMM yyyy'}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="project.name" title="Project Name"></kendo-grid-column>
              <kendo-grid-column field="slot" title="Slot Position" filter="numeric"></kendo-grid-column>
              <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="d-flex">
                    <!-- Edit Invoice Ads Icon -->
                    <span kendoTooltip title="Edit Invoice Ads" (click)="editInvoice(dataItem, addAdvertismentModal)"
                      style="cursor: pointer;" class="me-3">
                      <i class="fas fa-edit text-primary"></i>
                    </span>

                    <!-- Delete Invoice Ads Icon -->
                    <span kendoTooltip title="Delete Invoice Ads" (click)="deletdinvoiceAdd(dataItem)"
                      style="cursor: pointer;">
                      <i class="fas fa-trash-alt text-danger"></i>
                    </span>
                  </div>
                </ng-template>
              </kendo-grid-command-column>



            </kendo-grid>
          </div>

          <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
            <kendo-grid [kendoGridBinding]="mySelection.length>0 ? mySelection : billInvoicegridData.data" #pdfGrid>
              <!-- <kendo-grid
              [kendoGridBinding]="mySelection.length>0 ? mySelection:billAllInvoiceList.length>0?billAllInvoiceList:billInvoicegridData.data"
              #pdfGrid> -->
              <kendo-grid-column field="project.name" title="Project"></kendo-grid-column>

              <!-- <kendo-grid-column field="fileUrl" title="Image" [width]="100" [class]="{ 'text-center': true }">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <img class="rounded" [src]="dataItem.fileUrl" [width]="30" />
                </ng-template>
              </kendo-grid-column> -->
              <!-- <kendo-grid-column field="From_to_date" title="From to date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.from | date: 'dd MMM yyyy'}}--{{dataItem.to | date: 'dd MMM yyyy'}}
                </ng-template>
              </kendo-grid-column> -->
              <kendo-grid-column field="from" title="From Date" filter="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.from | date: 'dd MMM yyyy'}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="to" title="To Date" filter="date">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.to | date: 'dd MMM yyyy'}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="slot" title="Slot Position"></kendo-grid-column>
              <!-- <kendo-grid-column field="fileUrl" title="Image"></kendo-grid-column> -->
              <kendo-grid-pdf fileName="invoice.pdf" [allPages]="true" paperSize="A4"
                [landscape]="true"></kendo-grid-pdf>
              <kendo-grid-excel fileName="invoice.xlsx"></kendo-grid-excel>
            </kendo-grid>

          </div>

        </div>
      </div>
    </div>
  </div>


</div>

<!-- Add Advertisment modal -->
<ng-template #addAdvertismentModal>
  <div class="modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        @if(invoiceId){<h5 class="modal-title"> Edit advertisement</h5>}
        @else {<h5 class="modal-title">Add new advertisement</h5>}
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="invoiceAdd?.hide()"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="invoiceAddForm">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center mb-3">
              <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" formControlName="files"
                [multiple]="false" (select)="handleFileSelected($event)" [concurrent]="false" (upload)="onUpload()"
                (success)="onSuccess()" (remove)="onRemove($event)" [restrictions]="myRestrictions"
                accept=".png,.jpg,.jpeg">
              </kendo-upload>
              <div *ngIf="imageSrc" class="image-preview">
                <img [src]="imageSrc" alt="Image Preview" />
              </div>
              <!--  @if(imageUrl){ <img [src]="imageUrl" alt="Girl in a jacket" class="img-rounded me-3">} -->
              <!-- <span>{{fileName}}</span> -->
              @if(individualbillSubmitted && invoiceAddFormFormControls['files'].errors){
              <div class="invalid-feedback  me-3">
                @if(invoiceAddFormFormControls['files'].errors['required']){
                <div>{{prifileValidations.billCharge.file}}</div>}
              </div>}

            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="mb-3 col-lg-12">
                  <label>From & To Date</label>
                  <!-- <input type="text" class="form-control date" formControlName="date" (click)="hidden=!hidden"
                    name="date" autocomplete="off" bsDaterangepicker placeholder="Select Date" /> -->
                  <input type="text" class="form-control date" formControlName="date" placeholder="Select Date"
                    (click)="hidden = !hidden" name="daterange" autocomplete="off" bsDaterangepicker
                    [bsConfig]="datePickerConfig" />

                  @if(individualbillSubmitted && invoiceAddFormFormControls['date'].errors){
                  <div class="invalid-feedback">

                    @if(invoiceAddFormFormControls['date'].errors['required']){
                    <div>{{prifileValidations.billCharge.dateRange}}</div>}

                  </div>}

                  <!--  <input type="date" class="form-control date" (click)="hidden=!hidden" name="daterange"
                    formControlName="date" autocomplete="off" bsDaterangepicker /> -->
                </div>

                <div class="mb-3 col-lg-12">
                  <label>Project</label>
                  <ng-select [items]="configData?.projects" bindLabel="name" bindValue="id" formControlName="projectId"
                    id="projectId" [multiple]="false" placeholder="Select Project" [deselectOnClick]="true"></ng-select>

                  @if(individualbillSubmitted && invoiceAddFormFormControls['projectId'].errors){
                  <div class="invalid-feedback">
                    @if(invoiceAddFormFormControls['projectId'].errors['required']){
                    <div>{{prifileValidations.billCharge.ProjectId}}</div>}
                  </div>}
                </div>

                <div class="mb-3 col-lg-12  col-md-12 col-lg-12">
                  <label>Slot Position</label>{{selectedSlot|json}}
                  <ng-select [items]="slop_position" (change)="valueChange($event)" formControlName="slot"
                    [multiple]="false" bindLabel="name" bindValue="id" [placeholder]="'Select...'"></ng-select>
                  <!-- <input type="text" formControlName="slot" placeholder="Enter Slot Position" class="form-control" /> -->
                  @if(individualbillSubmitted && invoiceAddFormFormControls['slot'].errors){
                  <div class="invalid-feedback">
                    @if(invoiceAddFormFormControls['slot'].errors['required']){
                    <div>{{prifileValidations.billCharge.slot}}</div>}
                    @if(invoiceAddFormFormControls['slot'].errors['pattern']){
                    <div>{{prifileValidations.billCharge.slot_pattern}}</div>}
                  </div>}
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>

    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
      @if(invoiceId){<button type="button" [disabled]="isUploading" class="btn btn-primary waves-effect waves-light"
        (click)="saveinvoiceAdd('update')">update</button>}
      @else{ <button type="button" [disabled]="isUploading" class="btn btn-primary waves-effect waves-light"
        (click)="saveinvoiceAdd('Create')">Save</button>
      }
    </div>

  </div><!-- /.modal -->

</ng-template>
<!-- Add Advertisment modal End  -->
<!-- Add Advertisment modal End  -->