<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          @if(cdkStepper.selectedIndex === 1)
          { <button class="btn btn-primary d-flex align-items-center" (click)="rechargeExportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>}


          @if(cdkStepper.selectedIndex === 2){
          <button class="btn btn-primary d-flex align-items-center" (click)="walletsExportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>
          }

        </span>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div id="basic-example">
            <ng-stepper #cdkStepper [linear]="true" class="wizard custom-steeper-paddding">

              <cdk-step [optional]="false">
                <ng-template cdkStepLabel>
                  <span class="number">1.</span>
                  <span>Wallet Details</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 0){
                <section class="p-2">

                  <div class="row " *ngFor=" let item of walletDetails">
                    <div class="col-lg-3 mb-3">
                      <div class="mb-4">
                        <label for="">Consumer</label>
                        <p>{{item.consumer}}</p>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-3">
                      <div class="mb-4">
                        <label for="basicpill-lastname-input">Residential Unit</label>
                        <p>{{item.residentialUnit}}</p>
                      </div>
                    </div>

                    <div class="col-lg-3 mb-3">
                      <div class="mb-4">
                        <label for="basicpill-lastname-input">Current Balance</label>
                        <p><span>₹</span>{{item.currentBalance}}</p>
                      </div>
                    </div>
                    <div class="col-lg-3 mb-3">
                      <div class="mb-4">
                        <label for="basicpill-lastname-input">Last recharge amount</label>
                        <p><span>₹</span> {{item.lastRechargeAmount}}</p>
                      </div>
                    </div>
                    <div class="col-lg-12 mb-3">

                      <form class="row" [formGroup]="paybycashform">
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label for="basicpill-lastname-input">Enter amount to recharge <span
                                class="required">*</span></label>

                            <input type="text" class="form-control" formControlName="amount" placeholder="Enter Amount">
                            @if(submitted && paybycashformControls['amount'].errors ){

                            <div class="invalid-feedback">
                              @if(paybycashformControls['amount'].errors['required']){
                              <div>{{prifileValidations.billCharge.amount}}</div>}
                              @if(paybycashformControls['amount'].errors['max']){
                              <div>{{prifileValidations.billCharge.max_amount}}:{{max_amount}}</div>}

                              @if(paybycashformControls['amount'].errors['min']){
                              <div>{{prifileValidations.billCharge.min_amount}}:{{min_amount}}</div>}


                              @if(paybycashformControls['amount'].errors['pattern']){
                              <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
                            </div>}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label>Reason <span class="required" placeholder="Enter your reason here">*</span></label>
                            <textarea [placeholder]="'Enter your reason here'" formControlName="reason"
                              class="form-control" rows="1"></textarea>
                          </div>
                          @if(submitted && paybycashformControls['reason'].errors){
                          <div class="invalid-feedback">
                            @if(paybycashformControls['reason'].errors['required']){
                            <div>{{prifileValidations.billCharge.reason}}</div>}
                          </div>}
                        </div>
                        <!-- <div class="col-lg-2 d-flex align-items-end">
                          <div class="mb-3">
                            <button class="btn btn-primary ms-3" (click)="rechargeByCash()">Recharge</button>
                          </div>
                        </div> -->
                      </form>
                      <!-- <div class="d-flex justify-content-end">
                          <button class="btn btn-primary ms-3" (click)="rechargeByCash()">Recharge</button>
                        </div> -->
                      <!-- <div class="d-flex">
                          <button type="button" class="btn btn-outline-primary"><span>₹</span> 500</button>
                          <button type="button" class="btn btn-outline-primary ms-3"><span>₹</span> 2000</button>
                        </div> -->
                    </div>

                  </div>




                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="next list-inline-item" aria-disabled="false">
                      <button class="btn btn-primary me-3" (click)="rechargeByCash()">Recharge</button>
                      <button class="btn btn-primary" cdkStepperNext>Next</button>
                    </li>
                  </ul>

                </section>


                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">2.</span>
                  <span>Recharge History</span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 1){
                <div class="row">
                  <div class="col-lg-12">
                    <form>
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <label class="form-label">From Date -To Date </label>
                            <!-- <input type="text" class="form-control date" [(ngModel)]="fromDate" (bsValueChange)="selectDates($event)"
                                name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig" /> -->

                            <div class="position-relative">
                              <input type="text" class="form-control date"
                                [value]="fromDate ? (formatDateRange(fromDate)) : ''" placeholder="Select Date"
                                name="daterange" autocomplete="off" bsDaterangepicker [bsConfig]="datePickerConfig"
                                [(bsValue)]="fromDate" />
                              <span class="bx bx-calendar inputIcon" (click)="openCalendar()"></span>
                              <!-- Clear icon -->
                              <span class="bx bx-x inputIcon clear-icon" *ngIf="fromDate.length>0"
                                (click)="clearDate()"></span>
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-2 d-flex align-items-center">
                          <button class="btn btn-primary btn-md mt-2" (click)="filterBasedOnDate(1)">Filter</button>
                          <button class="btn btn-secondary btn-md mt-2 ms-2"
                            (click)="filterBasedOnDate(0)">Clear</button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
                <section>
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="rechargeHistorygridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                      [selectable]="true"
                      [pageable]="{buttonCount: buttonCount,info: info,type: type,pageSizes: pageSizes,previousNext: previousNext }"
                      [reorderable]="true" filterable="menu" [filter]="rechargeHisoryState.filter"
                      [sort]="rechargeHisoryState.sort" [columnMenu]="{ filter: true }"
                      (filterChange)="onRechargeHistoryFilterChange($event)" (sortChange)="onRechargeSortChange($event)"
                      [resizable]="true" scrollable="scrollable" (pageChange)="rechargeConfigPageChange($event)"
                      (selectionChange)="rechargeHistoryselectionChanged($event)"
                      (dataStateChange)="rechargeDataStateChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="rechargeSearch"
                          (valueChange)="onRechargeDataSearchFilter($event)"></kendo-textbox> -->
                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [clearButton]="true"
                            (valueChange)="onValueChange($event)"  (keydown.enter)="onSearchRechargeHistory()"
                             [style.height.px]="35" [(ngModel)]="rechargeSearch"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-md border-left-none"
                            (click)="onRechargeDataSearchFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>

                        <!-- <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [(ngModel)]="rechargeSearch"
                            placeholder="Search in all columns...">
                          </kendo-textbox>
                          <button class="btn btn-primary btn-md mt-2" style="height: 36px; margin-left: 5px;"
                            (click)="onRechargeDataSearchFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div> -->
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">

                          <!-- <button kendoGridPDFCommand class="btn btn-light me-2" (click)="onRechargeExport('Pdf')">
                          <span class="fas fa-file-pdf "> </span> &nbsp; Export to PDF</button> -->

                          <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                            (click)="onRechargeExport('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>
                        </div>

                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="orderId" title="Order Id"></kendo-grid-column>
                      <kendo-grid-column field="date" title="Date" filter="date">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.date | date: 'dd MMM yyyy hh:mm a'}}
                        </ng-template>

                      </kendo-grid-column>

                      <kendo-grid-column field="rechargeStatus" title="Recharge Status"></kendo-grid-column>
                      <kendo-grid-column field="reason" title="Reason"></kendo-grid-column>
                      <kendo-grid-column field="rechargeAmount" title="Recharge Amount" filter="numeric">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.rechargeAmount | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="currentBalance" title="Current Balance" filter="numeric">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.currentBalance | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="paymentRefNumber" title="Payment Reference Number"></kendo-grid-column>

                    </kendo-grid>
                  </div>

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionRecharge.length>0 ? mySelectionRecharge:getAllRechargeHistoryList.length>0?getAllRechargeHistoryList:rechargeHistorygridData.data"
                      #recharge>
                      <kendo-grid-column field="orderId" title="Order Id"></kendo-grid-column>
                      <kendo-grid-column field="date" title="Date"></kendo-grid-column>
                      <kendo-grid-column field="rechargeStatus" title="Recharge Status"></kendo-grid-column>
                      <kendo-grid-column field="rechargeAmount" title="Recharge Amount"></kendo-grid-column>
                      <kendo-grid-column field="reason" title="Reason" filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="currentBalance" title="Current Balance"></kendo-grid-column>
                      <kendo-grid-column field="paymentRefNumber" title="Payment Reference Number"></kendo-grid-column>
                      <kendo-grid-pdf fileName="RechargeHistory.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf>
                      <kendo-grid-excel fileName="RechargeHistory.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>

                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                    <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                        cdkStepperNext>Next</button></li>
                  </ul>
                </section>
                }
              </cdk-step>

              <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                  <span class="number">3.</span>
                  <span>Wallet History </span>
                </ng-template>
                @if(cdkStepper.selectedIndex === 2){
                <app-wallet-history></app-wallet-history>
                <!-- <section>
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="walletHistorygridData" [pageSize]="wallet_historyPageSize" [skip]="skip"
                      [sortable]="true" [selectable]="true"
                      [pageable]="{ buttonCount: buttonCount, info: info, type: type,pageSizes: pageSizes, previousNext: previousNext}"
                      [reorderable]="true" filterable="menu" [filter]="walletHisoryState.filter"
                      [skip]="walletHisoryState.skip" [sort]="walletHisoryState.sort" [columnMenu]="{ filter: true }"
                      (sortChange)="onWalletSortChange($event)" [resizable]="true" scrollable="none"
                      (filterChange)="onWalletHistoryFilterChange($event)" (pageChange)="walletConfigPageChange($event)"
                      (selectionChange)="walletHistoryselectionChanged($event)"
                      (dataStateChange)="walletDataStateChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                          [(ngModel)]="walletSearch" (valueChange)="onWalletDataSearchFilter($event)"></kendo-textbox>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <div class="sm-device d-flex justify-content-between">

                          
                          <button kendoGridAddCommand class="btn btn-light" (click)="onPdfExport('Excel')">
                            <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                          </button>

                        </div>

                      </ng-template>
                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="residentialUnit" title="Residential Unit"></kendo-grid-column>
                      <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                      <kendo-grid-column field="meterNo" title="Meter Number"></kendo-grid-column>
                      <kendo-grid-column field="consumption" title="Consumption"></kendo-grid-column>
                      <kendo-grid-column field="unitRate" title="Unit Rate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.unitRate | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="amountDeducted" title="Amount Deducted">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.amountDeducted | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="availableBalance" title="Available Balance">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.availableBalance | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>

                    </kendo-grid>
                  </div>

                  <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                    <kendo-grid
                      [kendoGridBinding]="mySelectionWallet.length>0 ? mySelectionWallet:getAllWalletHistoryList.length>0?getAllWalletHistoryList:walletHistorygridData.data"
                      #walletGrid>
                      <kendo-grid-column field="residentialUnit" title="Residential Unit"></kendo-grid-column>
                      <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                      <kendo-grid-column field="meterNo" title="Meter Number"></kendo-grid-column>
                      <kendo-grid-column field="consumption" title="Consumption"></kendo-grid-column>
                      <kendo-grid-column field="unitRate" title="Unit Rate"></kendo-grid-column>
                      <kendo-grid-column field="amountDeducted" title="Amount Deducted"></kendo-grid-column>
                      <kendo-grid-column field="availableBalance" title="Available Balance"></kendo-grid-column>
                      <kendo-grid-pdf fileName="WalletHistory.pdf" [allPages]="true" paperSize="A4"
                        [landscape]="true"></kendo-grid-pdf>
                      <kendo-grid-excel fileName="WalletHistory.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                  </div>





                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                        cdkStepperPrevious>Previous</button></li>
                  </ul>
                </section> -->

                }
              </cdk-step>

            </ng-stepper>
          </div>

        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>

</div>


<!-- Add Gateways modal -->
<div bsModal #addGatewayModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title">Add Gateway</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
          (click)="addGatewayModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal">
          <div class="mb-3">
            <label>Gateway Serial Number</label>
            <input type="text" class="form-control" />

          </div>

          <div class="mb-3">
            <label>Gateway</label>
            <input type="text" class="form-control" />

          </div>


        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Save Gateway</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Add Gateways modal End  -->

<!-- Add Monitor modal -->
<div bsModal #addMoniterModal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title">Add Monitoring Devices</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
          (click)="addMoniterModal?.hide()"></button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal">
          <div class="mb-3">
            <label>Utility</label>
            <input type="text" class="form-control" />

          </div>

          <div class="mb-3">
            <label>Meter Number</label>
            <input type="text" class="form-control" />

          </div>


        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
        <button type="button" class="btn btn-primary waves-effect waves-light">Save Devices</button>
      </div>
    </div><!-- /.modal -->
  </div>
</div>
<!-- Add Monitor modal End  -->